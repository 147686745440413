import { USER_TYPE_KEY } from './constants'
import nookies from 'nookies'
import { put, headers } from '../lib/fetch'
import {
  ONE_YEAR_IN_MS,
  ONE_MONTH_IN_MS,
} from '../components/CheckoutAddressProvider'

export const getUserType = ctx => {
  const { [USER_TYPE_KEY]: userType } = nookies.get(ctx || {}) || {}

  return userType || null
}

export const setUserType = (userType, isLoggedIn, shouldReload, ctx) => {
  const options = {
    path: '/',
    maxAge: isLoggedIn ? ONE_YEAR_IN_MS : ONE_MONTH_IN_MS,
  }
  // set local shopping mode
  nookies.set(ctx, USER_TYPE_KEY, userType, options)

  // call shopping-mode API
  if (isLoggedIn) {
    put('profile/shopping-mode', {
      headers: headers(ctx),
      body: JSON.stringify({
        'og-shopping-mode': userType === 'B2B' ? 'business' : 'personal',
      }),
    })
  }
  if (shouldReload) {
    window.location.reload()
  }
}
