import React from 'react'
import styled from 'styled-components'
import isEmpty from 'lodash/isEmpty'
import Arrow from '../icons/Arrow'

import NoImg from '../icons/NoImg'
import ProgressiveImage from '../ProgressiveImage/ProgressiveImage'
import Typography from '@ntuctech/devex-tangram/Typography/Typography'

const StyledContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding: 1rem 0 1.125rem 0.5rem;
  background-color: white;

  border-bottom: 1px solid #eaeaea;
`

const ItemCountContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  gap: 0.5rem;
`

const StyledArrow = styled(Arrow)`
  height: 0.75rem;
  width: 0.75rem;
  margin: 0 0.5rem;
`

const StyledInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 0.5rem;
  justify-content: space-between;

  .reasonLabel {
    color: #dd0d42;
  }

  .oldQ {
    color: #6c6c6c;
  }

  .newQ {
    color: #dd0d42;
  }
`

const StyledCartCounter = styled.span`
  background-color: #eaeaea;
  border-radius: 2px;
  width: fit-content;
  min-width: 2.5rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
`

const StyledNoImageIcon = styled(NoImg)`
  min-width: 4.5rem;
  max-width: 4.5rem;
  height: 4.5rem;
  display: inline-block;
  padding: 0.5rem;
`

const ImageContainer = styled.div`
  width: 4.5rem;
  height: 4.5rem;
  display: inline-block;
  position: relative;
`

const UnapplicableProduct = props => {
  const { item } = props
  if (isEmpty(item) || !item.product) {
    return null
  }
  const {
    newQ,
    oldQ,
    product: { name, description, images },
  } = item

  return (
    <StyledContainer data-testid="unapplicable-product">
      {images && images.length > 0 ? (
        <ImageContainer data-testid="unapplicable-product-image">
          <ProgressiveImage
            alt={description || 'product-image'}
            src={typeof images[0] === 'string' && `${images[0]}?w=320&q=60`}
            originalSrc={`${images[0]}?w=320&q=60`}
            objectFitContain={true}
            unoptimized={true}
          />
        </ImageContainer>
      ) : (
        <StyledNoImageIcon />
      )}

      <StyledInfo>
        <Typography data-testid="unapplicable-product-name">{name}</Typography>

        <ItemCountContainer>
          <StyledCartCounter>
            <Typography
              variant="h5"
              className="oldQ"
              data-testid="unapplicable-product-quantity"
            >
              {oldQ}
            </Typography>
          </StyledCartCounter>
          <StyledArrow />
          <StyledCartCounter>
            <Typography variant="h5" className="newQ">
              {newQ}
            </Typography>
          </StyledCartCounter>
          <Typography variant="subhead1" className={'reasonLabel'}>
            Not Applicable
          </Typography>
        </ItemCountContainer>
      </StyledInfo>
    </StyledContainer>
  )
}

export default UnapplicableProduct
