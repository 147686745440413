import React from 'react'

const Arrow = ({ title, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    {...props}
  >
    <titile>{title}</titile>
    <path
      fill="#6C6C6C"
      fillRule="nonzero"
      d="M.7 5.195h8.922L6.265 1.39a.811.811 0 0 1-.218-.567c0-.22.065-.411.194-.576A.584.584 0 0 1 6.715 0c.187 0 .353.073.498.22l4.57 5.195c.144.158.217.35.217.576a.823.823 0 0 1-.218.576l-4.6 5.213a.617.617 0 0 1-.467.22.612.612 0 0 1-.497-.256.816.816 0 0 1-.194-.567.881.881 0 0 1 .21-.567L9.59 6.805H.685a.617.617 0 0 1-.466-.22.819.819 0 0 1-.218-.53.86.86 0 0 1 .194-.604.633.633 0 0 1 .505-.256z"
    />
  </svg>
)

export default Arrow
