const { SPLIT_FEATURES } = require('../constants/feature')

module.exports = {
  /**
   * add fallback value for config in format of:
   * {
   *    isEnabled: true | false,
   *    config: {...} | null
   * }
   *
   * this will be used as fallback when split is down or not responding
   */
  [SPLIT_FEATURES.OOS_REDUCED_QUANTITY]: {
    isEnabled: false,
    config: null,
  },
}
