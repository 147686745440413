import { GTM_ENV_VAR as GEV } from '../../lib/eventTrackerFunction'
import { getTimeslotDiffToToday } from '../../components/CheckoutForm/utils'
import { compact } from 'lodash'
import { AMP_EVENT_NAME, getPageTypeByUrl } from '../../lib/amplitude'
import {
  getFirstAvailableTimeslotWithDefault,
  getTrackingPayloadForItemLevel,
} from './trackingUtils'

const getFirstFpAvailableTimeslot = sellersTimeSlots => {
  const fpSellerTimeslots = sellersTimeSlots.find(
    ({ sellerInfo }) => !sellerInfo.id
  )
  return getFirstAvailableTimeslotWithDefault(fpSellerTimeslots)
}

const getCheckedCartItems = (sellersItems, fpFulfillmentType) => {
  const checkedItemSellerIds = new Set()
  const fulfillmentTypeMap = {}

  let cartItems = []
  for (const sellerItems of sellersItems) {
    const currentFulfillmentType = sellerItems.sellerInfo.id
      ? 'DF'
      : fpFulfillmentType
    for (const item of Object.values(sellerItems.items)) {
      if (item.isChecked) {
        cartItems.push(item)
        checkedItemSellerIds.add(sellerItems.sellerInfo.id || 0)
        fulfillmentTypeMap[item.id] = currentFulfillmentType
      }
    }
  }
  cartItems = cartItems.sort((a, b) => a.__serverIndex__ - b.__serverIndex__)

  return {
    cartItems,
    checkedItemSellerIds,
    fulfillmentTypeMap,
  }
}

export const trackCartLoadedEvent = ({
  track,
  checkoutAddress,
  sellersTimeSlots,
  sellersItems,
}) => {
  const fulfillmentTypes = []
  const vendorIds = []
  const fpFulfillmentType = checkoutAddress?.storeType

  const { cartItems, checkedItemSellerIds, fulfillmentTypeMap } =
    getCheckedCartItems(sellersItems, fpFulfillmentType)

  const timeslots = Object.values(sellersTimeSlots || {})
    .filter(({ sellerInfo }) => checkedItemSellerIds.has(sellerInfo.id || 0))
    .map(sellerTimeslots => {
      vendorIds.push(sellerTimeslots?.sellerInfo?.id || 0)
      fulfillmentTypes.push(
        sellerTimeslots?.sellerInfo?.id ? 'DF' : fpFulfillmentType
      )
      return getFirstAvailableTimeslotWithDefault(sellerTimeslots)
    })
    .filter(Boolean)

  const itemLevelPayload = getTrackingPayloadForItemLevel(
    cartItems,
    fulfillmentTypeMap
  )

  const event = {
    [GEV.FUL_CENTER]: fulfillmentTypes,
    [GEV.AMPLITUDE_EVENT_NAME]: AMP_EVENT_NAME.CART_LOADED,
    [GEV.AMPLITUDE_TIMESLOT_AVAILABLE_SLOT_TIME]: timeslots.map(
      timeslot => timeslot.startTime
    ),
    [GEV.AMPLITUDE_DAYS_TO_AVAILABLE_SLOTS]: timeslots.map(
      timeslot => timeslot.daysToTimeslot
    ),
    [GEV.AMPLITUDE_HOURS_TO_AVAILABLE_SLOTS]: timeslots.map(
      timeslot => timeslot.hoursToTimeslot
    ),

    [GEV.AMPLITUDE_CTA_LOCATION]: {
      pageName: getPageTypeByUrl(),
    },
    [GEV.AMPLITUDE_VENDOR_ID]: vendorIds,

    ...itemLevelPayload,
  }

  track(event)
}

const getMoreThanNextDayDeliveryItemsPayload = (
  sellersItems,
  moreThanNextDayDeliveryProductIds,
  fpFulfillmentType
) => {
  const fulfillmentTypeMap = {}
  const moreThanNextDayDeliveryItems = []
  for (const sellerItems of sellersItems) {
    const currentFulfillmentType = sellerItems.sellerInfo.id
      ? 'DF'
      : fpFulfillmentType
    for (const item of Object.values(sellerItems.items)) {
      if (moreThanNextDayDeliveryProductIds.includes(item.id)) {
        moreThanNextDayDeliveryItems.push(item)
        fulfillmentTypeMap[item.id] = currentFulfillmentType
      }
    }
  }

  return getTrackingPayloadForItemLevel(
    moreThanNextDayDeliveryItems,
    fulfillmentTypeMap
  )
}

export const trackEarlierDeliveryClicked = ({
  track,
  sellersTimeSlots,
  sellersItems,
  moreThanNextDayDeliveryProductIds,
  checkoutAddress,
}) => {
  const fpFulfillmentType = checkoutAddress?.storeType
  const firstFpAvailableTimeslot = getFirstFpAvailableTimeslot(sellersTimeSlots)

  const fpSellersItems = sellersItems.find(({ sellerInfo }) => !sellerInfo.id)
  const potentialEarlierTimeslot =
    fpSellersItems.sellerInfo.sdndEarliestTimeSlotRaw

  const {
    startTime: potentialTimeslot,
    daysToTimeslot: daysToPotentialTimeslot,
    hoursToTimeslot: hoursToPotentialTimeslot,
  } = getTimeslotDiffToToday(potentialEarlierTimeslot)

  const itemLevelPayload = getMoreThanNextDayDeliveryItemsPayload(
    sellersItems,
    moreThanNextDayDeliveryProductIds,
    fpFulfillmentType
  )

  const event = {
    [GEV.AMPLITUDE_EVENT_NAME]: AMP_EVENT_NAME.EARLIER_DELIVERY_POPUP_CLICKED,
    [GEV.AMPLITUDE_CTA_LOCATION]: {
      pageName: getPageTypeByUrl(),
    },
    [GEV.AMPLITUDE_TIMESLOT_AVAILABLE_SLOT_TIME]:
      firstFpAvailableTimeslot.startTime,
    [GEV.AMPLITUDE_HOURS_TO_AVAILABLE_SLOTS]:
      firstFpAvailableTimeslot.hoursToTimeslot,
    [GEV.AMPLITUDE_DAYS_TO_AVAILABLE_SLOTS]:
      firstFpAvailableTimeslot.daysToTimeslot,
    [GEV.AMPLITUDE_POTENTIAL_TIMESLOT_SHOWN]: potentialTimeslot,
    [GEV.AMPLITUDE_POTENTIAL_DAYS_TO_AVAILABLE_SLOT]: daysToPotentialTimeslot,
    [GEV.AMPLITUDE_POTENTIAL_HOURS_TO_AVAILABLE_SLOT]: hoursToPotentialTimeslot,
    [GEV.AMPLITUDE_VENDOR_ID]: [0],
    ...itemLevelPayload,
  }

  track(event)
}

export const trackEarlierDeliveryConfirmed = ({
  track,
  sellersTimeSlots,
  sellersItems,
  unselectAll,
  moreThanNextDayDeliveryProductIds,
  checkoutAddress,
}) => {
  const fpFulfillmentType = checkoutAddress?.storeType
  const firstFpAvailableTimeslot = getFirstFpAvailableTimeslot(sellersTimeSlots)

  const fpSellersItems = sellersItems.find(({ sellerInfo }) => !sellerInfo.id)

  const {
    startTime: potentialTimeslot,
    daysToTimeslot: daysToPotentialTimeslot,
    hoursToTimeslot: hoursToPotentialTimeslot,
  } = getTimeslotDiffToToday(fpSellersItems.sellerInfo.sdndEarliestTimeSlotRaw)

  const itemLevelPayload = getMoreThanNextDayDeliveryItemsPayload(
    sellersItems,
    moreThanNextDayDeliveryProductIds,
    fpFulfillmentType
  )

  const event = {
    [GEV.AMPLITUDE_EVENT_NAME]: AMP_EVENT_NAME.EARLIER_DELIVERY_POPUP_CONFIRMED,
    [GEV.AMPLITUDE_CTA_LOCATION]: {
      pageName: getPageTypeByUrl(),
    },
    [GEV.AMPLITUDE_TIMESLOT_AVAILABLE_SLOT_TIME]: [
      firstFpAvailableTimeslot.startTime,
    ],
    [GEV.AMPLITUDE_HOURS_TO_AVAILABLE_SLOTS]:
      firstFpAvailableTimeslot.hoursToTimeslot,
    [GEV.AMPLITUDE_DAYS_TO_AVAILABLE_SLOTS]:
      firstFpAvailableTimeslot.daysToTimeslot,
    [GEV.AMPLITUDE_POTENTIAL_TIMESLOT_SHOWN]: potentialTimeslot,
    [GEV.AMPLITUDE_POTENTIAL_DAYS_TO_AVAILABLE_SLOT]: daysToPotentialTimeslot,
    [GEV.AMPLITUDE_POTENTIAL_HOURS_TO_AVAILABLE_SLOT]: hoursToPotentialTimeslot,

    [GEV.AMPLITUDE_MESSAGE]: unselectAll ? 'unselect all' : 'keep items',
    [GEV.AMPLITUDE_VENDOR_ID]: [0],
    ...itemLevelPayload,
  }

  track(event)
}

export const trackProductSelect = ({
  track,
  selected,
  product,
  ctaLocation,
  sellerInfo,
  fpStoreType,
}) => {
  const itemLevelPayload = product
    ? getTrackingPayloadForItemLevel([product], {
        [product.id]: fpStoreType,
      })
    : {}
  const event = {
    [GEV.AMPLITUDE_EVENT_NAME]: selected
      ? AMP_EVENT_NAME.CART_PRODUCT_CHECK_SELECTED
      : AMP_EVENT_NAME.CART_PRODUCT_UNCHECK_SELECTED,
    [GEV.AMPLITUDE_CTA_LOCATION]: ctaLocation,
    [GEV.PROMOTION_COUNT]: product?.offers?.length || 0,
    [GEV.PROD_NAME]: product?.name,
    [GEV.AMPLITUDE_VENDOR_ID]: [sellerInfo?.id || 0],
    [GEV.AMPLITUDE_PRODUCT_FULFILMENT_TYPE]: fpStoreType,
    ...itemLevelPayload,
  }
  if (sellerInfo?.id) {
    event[GEV.AMPLITUDE_PRODUCT_FULFILMENT_TYPE] = ['DF']
    event[GEV.FUL_CENTER] = 'DF'
  }

  track(event)
}

export const trackSellerSelect = ({
  track,
  selected,
  ctaLocation,
  sellerInfo,
}) => {
  const event = {
    [GEV.AMPLITUDE_EVENT_NAME]: selected
      ? AMP_EVENT_NAME.CART_SELLER_CHECK_SELECTED
      : AMP_EVENT_NAME.CART_SELLER_UNCHECK_SELECTED,
    [GEV.AMPLITUDE_CTA_LOCATION]: ctaLocation,
    [GEV.AMPLITUDE_VENDOR_ID]: [sellerInfo?.id || 0],
    [GEV.AMPLITUDE_VENDOR_NAME]: sellerInfo?.name,
    [GEV.AMPLITUDE_FULFILMENT_TYPE_IN_ARRAY]: true,
  }

  if (sellerInfo?.id) {
    event[GEV.FUL_CENTER] = 'DF'
  }

  track(event)
}

export const trackCartCheckoutClicked = ({
  track,
  checkoutAddress,
  totalCouponDiscount,
  cartGrossValue,
  cartNetValue,
  deliveryCharge,
  currentServiceFee,
  sellersItems,
  sellersTimeSlots,
  linkpointRedemptionDollarAmount,
}) => {
  const fulfillmentTypes = []
  const fpFulfillmentType = checkoutAddress?.storeType

  const { cartItems, checkedItemSellerIds, fulfillmentTypeMap } =
    getCheckedCartItems(sellersItems, fpFulfillmentType)

  const timeslots = Object.values(sellersTimeSlots || {})
    .filter(({ sellerInfo }) => checkedItemSellerIds.has(sellerInfo.id || 0))
    .map(sellerTimeslots => {
      fulfillmentTypes.push(
        sellerTimeslots?.sellerInfo?.id ? 'DF' : fpFulfillmentType
      )
      return getFirstAvailableTimeslotWithDefault(sellerTimeslots)
    })
    .filter(Boolean)

  const itemLevelPayload = getTrackingPayloadForItemLevel(
    cartItems,
    fulfillmentTypeMap
  )

  const orderPromotionTypes = []
  const orderPromotionDescriptions = []
  for (const item of cartItems) {
    orderPromotionTypes.push(
      ...compact((item.offers || []).map(offer => offer?.details?.offerType))
    )
    orderPromotionDescriptions.push(
      ...compact((item.offers || []).map(offer => offer?.details?.description))
    )
  }

  track({
    // legacy props
    [GEV.EVENT_CAT]: 'Order-Cart',
    [GEV.EVENT_ACTION]: 'Click',
    [GEV.EVENT_LABEL]: 'Cart-Checkout',
    [GEV.PAGE_TYPE]: 'Cart',
    [GEV.SCREEN_NAME]: 'Order-Cart',
    [GEV.AMPLITUDE_LIST_CATS]: itemLevelPayload[GEV.PROD_ID],

    [GEV.AMPLITUDE_EVENT_NAME]: AMP_EVENT_NAME.CHECKOUT_CLICKED,
    [GEV.FUL_CENTER]: fulfillmentTypes,
    [GEV.AMPLITUDE_CTA_LOCATION]: {
      pageName: getPageTypeByUrl(),
    },
    [GEV.AMPLITUDE_FINAL_ORDER_VALUE]: cartGrossValue,
    [GEV.AMPLITUDE_FINAL_PAYMENT_VALUE]: cartNetValue,
    [GEV.AMPLITUDE_DELIVERY_FEE]: deliveryCharge.toFixed(2),
    [GEV.AMPLITUDE_SERVICE_FEE]: currentServiceFee.toFixed(2),
    [GEV.AMPLITUDE_LINKPOINT_REDEMPTION]: linkpointRedemptionDollarAmount || 0,
    [GEV.AMPLITUDE_ORDER_PROMOTION_AMOUNT]: totalCouponDiscount,

    [GEV.AMPLITUDE_TIMESLOT_AVAILABLE_SLOT_TIME]: timeslots.map(
      timeslot => timeslot.startTime
    ),
    [GEV.AMPLITUDE_DAYS_TO_AVAILABLE_SLOTS]: timeslots.map(
      timeslot => timeslot.daysToTimeslot
    ),
    [GEV.AMPLITUDE_HOURS_TO_AVAILABLE_SLOTS]: timeslots.map(
      timeslot => timeslot.hoursToTimeslot
    ),

    [GEV.AMPLITUDE_ORDER_PROMOTION_TYPE]: orderPromotionTypes,
    [GEV.AMPLITUDE_ORDER_PROMOTION_DESCRIPTION]: orderPromotionDescriptions,

    ...itemLevelPayload,
  })
}

export const trackLinkpointRedeemSelected = ({ track, lpChecked }) => {
  track({
    [GEV.AMPLITUDE_EVENT_NAME]: AMP_EVENT_NAME.LINKPOINT_REDEEM_SELECTED,
    [GEV.AMPLITUDE_FULFILMENT_TYPE_IN_ARRAY]: true,
    [GEV.AMPLITUDE_CTA_LOCATION]: {
      pageName: getPageTypeByUrl(),
    },
    [GEV.AMPLITUDE_REDEEM_LINKPOINT_OPTION]: lpChecked ? 'yes' : 'no',
  })
}

export const trackCartPurchaseWithPurchaseClicked = ({ track }) => {
  track({
    [GEV.EVENT_CAT]: 'Ecommerce-Engagement',
    [GEV.EVENT_ACTION]: 'Click',
    [GEV.EVENT_LABEL]: 'Loc=Cart-PWP',
    [GEV.AMPLITUDE_FULFILMENT_TYPE_IN_ARRAY]: true,
    [GEV.AMPLITUDE_EVENT_NAME]:
      AMP_EVENT_NAME.CART_PURCHASE_WITH_PURCHASE_CLICKED,
    [GEV.AMPLITUDE_CTA_LOCATION]: {
      pageName: getPageTypeByUrl(),
    },
  })
}

export const trackAlcoholCheckboxSelected = ({ track }) => {
  track({
    [GEV.AMPLITUDE_EVENT_NAME]: AMP_EVENT_NAME.ALCOHOL_CHECKBOX_SELECTED,
    [GEV.AMPLITUDE_CTA_LOCATION]: {
      pageName: getPageTypeByUrl(),
    },
  })
}

export const trackAlcoholErrorNotificationViewed = ({ track }) => {
  track({
    [GEV.AMPLITUDE_EVENT_NAME]:
      AMP_EVENT_NAME.ALCOHOL_ERROR_NOTIFICATION_VIEWED,
    [GEV.AMPLITUDE_CTA_LOCATION]: {
      pageName: getPageTypeByUrl(),
    },
  })
}
