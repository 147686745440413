import getConfig from 'next/config'
import { get, del, patch, headers } from './fetch'

const {
  publicRuntimeConfig: { AUTH0_IDENTITY_API },
} = getConfig()

const updatePreferences = value =>
  patch(`${AUTH0_IDENTITY_API}/sdk/user/profile`, {
    headers: headers(),
    body: JSON.stringify({
      preferences: {
        fairprice: {
          channels: {
            email: value,
            text: value,
          },
        },
      },
    }),
  })

const deleteAuthoMetadata = metaData =>
  del(`${AUTH0_IDENTITY_API}/sdk/user-metadata/${metaData}`, {
    headers: headers(),
  })

const getDetailsPartialMatchAccount = matchType =>
  get(`${AUTH0_IDENTITY_API}/sdk/user/link-account?matchType=${matchType}`, {
    headers: headers(null, true),
  })

const updateMatchingRecordStatus = (id, status) => {
  return patch(`${AUTH0_IDENTITY_API}/sdk/user/link-account/update`, {
    headers: headers(null, true),
    body: JSON.stringify({
      'id': id,
      'status': status,
      'reason': '',
    }),
  })
    .then(() => {
      // no response obj, do nothing
    })
    .catch(() => {
      // don't do anything if error
    })
}

module.exports = {
  updatePreferences,
  deleteAuthoMetadata,
  getDetailsPartialMatchAccount,
  updateMatchingRecordStatus,
}
