import jwtDecode from 'jwt-decode'

export const decodeClaim = (claim, checkerFn) => {
  try {
    if (!claim) {
      return false
    }
    const decodedToken = jwtDecode(claim)

    return checkerFn(decodedToken)
  } catch (e) {
    return false
  }
}
