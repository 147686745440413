import { GTM_ENV_VAR as GEV } from '../../lib/eventTrackerFunction'

import some from 'lodash/some'
import differenceInCalendarDays from 'date-fns/difference_in_calendar_days'
import differenceInHours from 'date-fns/difference_in_hours'

export const getTimeslotDiffToToday = timeslot => {
  const daysToTimeslot = timeslot
    ? differenceInCalendarDays(new Date(timeslot.startTime), new Date())
    : null
  const hoursToTimeslot = timeslot
    ? differenceInHours(new Date(timeslot.startTime), new Date())
    : null
  return {
    daysToTimeslot,
    hoursToTimeslot,
    startTime: timeslot?.startTime,
  }
}

export const getFirstAvailableTimeslot = timeslots => {
  let firstAvailableTimeslot
  Object.values(timeslots || {}).forEach(day => {
    if (firstAvailableTimeslot) {
      return
    }
    const firstAvailTimeslot = some(day, slot => slot.available)
    if (firstAvailTimeslot) {
      firstAvailableTimeslot = day.find(slot => slot.available)
    }
  })
  if (!firstAvailableTimeslot) {
    return null
  }

  return getTimeslotDiffToToday(firstAvailableTimeslot)
}

export const getFirstAvailableSellerTimeslot = sellersTimeSlots => {
  const timeslots = Object.values(sellersTimeSlots || {})
    .map(({ slot }) => {
      return getFirstAvailableTimeslot(slot)
    })
    .filter(Boolean)

  if (timeslots.length === 0) {
    return null
  }

  let earliestTimeslot = timeslots[0]

  for (const timeslot of timeslots) {
    if (earliestTimeslot.hoursToTimeslot > timeslot.hoursToTimeslot) {
      earliestTimeslot = timeslot
    }
  }

  return earliestTimeslot
}

export const getSellersTimeslotClickedProps = sellersTimeSlots => {
  const firstAvailableTimeslot =
    getFirstAvailableSellerTimeslot(sellersTimeSlots)

  return {
    [GEV.AMPLITUDE_SLOTS_AVAILABILITY_STATUS]: !firstAvailableTimeslot
      ? 'No'
      : 'Yes', // expected to always be 'Yes' because user is not blocked from being able to check out if timeslot is not available
    [GEV.AMPLITUDE_DAYS_TO_AVAILABLE_SLOTS]:
      firstAvailableTimeslot?.daysToTimeslot || null,
    [GEV.AMPLITUDE_HOURS_TO_AVAILABLE_SLOTS]:
      firstAvailableTimeslot?.hoursToTimeslot || null,
    [GEV.AMPLITUDE_TIMESLOT_AVAILABLE_SLOT_TIME]:
      firstAvailableTimeslot?.startTime,
    [GEV.AMPLITUDE_TIMESLOT_CURRENT_TIME]: new Date(),
  }
}
