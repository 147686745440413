const { SplitConfigAdapter } = require('./SplitConfigAdapter')
const ConfigFallback = require('./fallback')

let globalObject = null

/*
  We are using singelton class pattern to create only one object for ConfigService class.
  We are using this class to store split.io configs
*/
class ConfigService {
  constructor(adapter) {
    this.adapter = adapter
    this.config = this.adapter.unparseValues(ConfigFallback)

    if (!globalObject) {
      globalObject = this
    }
    return globalObject
  }

  setConfigMethod = value => {
    this.config = value
  }

  getConfigMethod = key => {
    return this.config[key]
  }

  getAllConfigMethod = () => {
    return this.config || {}
  }

  _setConfigValuesMethod = values => {
    this.config = this.adapter.unparseValues(values)
  }

  getConfigForMethod = (key, options) => {
    const rawConfig = this.config[key]
    const isEnabled = this.adapter.isConfigEnabled(rawConfig, options)
    const configuration = this.adapter.parseConfigProperty(rawConfig)

    return {
      isEnabled,
      configuration,
      raw: rawConfig,
    }
  }
}

const configObject = new ConfigService(new SplitConfigAdapter())

const setConfig = configObject.setConfigMethod

const getExpConfig = configObject.getConfigMethod

const getAllConfig = configObject.getAllConfigMethod

const getConfigFor = configObject.getConfigForMethod

const _setConfigValues = configObject._setConfigValuesMethod

module.exports = {
  setConfig,
  getExpConfig,
  getAllConfig,
  getConfigFor,
  _setConfigValues,
}
