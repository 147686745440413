import React from 'react'
import { StyledPopup, StyledPopupBody } from './StyledPopup'
import getTime from 'date-fns/get_time'
import { createPortal } from 'react-dom'
import { disablePageScroll } from '../../lib/utils'

// CONSTANT
const ESC_KEY = 27

const DURATION_MS = 300

class Popup extends React.Component {
  constructor() {
    super()

    this.state = {
      isShown: false,
    }

    this.handleClick = this.handleClick.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.onClose = this.onClose.bind(this)
  }

  changeBodyStyle(isOpeningPopup) {
    disablePageScroll(isOpeningPopup)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isShown !== this.state.isShown) {
      disablePageScroll(this.state.isShown)
    }
  }

  componentDidMount() {
    this.setState({ isShown: true })
    disablePageScroll(true)
    if (this.modalRef && this.props.autoFocus) {
      this.modalRef.focus()
    }

    if (this.props.disableScroll) {
      this.changeBodyStyle(true)
    }

    this.openTime = getTime(new Date())
  }
  componentWillUnmount() {
    if (this.props.disableScroll) {
      this.changeBodyStyle(false)
    }
  }

  handleKeyDown(event) {
    // forceAcknowledge decides if the user can close the popup without taking action
    const forceAcknowledge = this.props.forceAcknowledge
      ? this.props.forceAcknowledge
      : false
    if (forceAcknowledge) {
      return
    }

    // Check if ESC key is pressed
    if (event.which === ESC_KEY) {
      this.onClose()
    }
  }

  onClose() {
    this.closeTime = getTime(new Date())
    if (this.closeTime - this.openTime < DURATION_MS) {
      return
    }
    if (this.props.disableScroll) {
      this.changeBodyStyle(false)
    }
    this.props.onClose &&
      typeof this.props.onClose === 'function' &&
      this.props.onClose()
    this.setState({ isShown: false })
  }

  handleClick(event) {
    // forceAcknowledge decides if the user can close the popup without taking action
    const forceAcknowledge = this.props.forceAcknowledge
      ? this.props.forceAcknowledge
      : false
    if (forceAcknowledge) {
      return
    }
    event.stopPropagation()
    // Check if the target is modal-backdrop
    if (this.modalRef === event.target && !this.props.disableBackdropClosing) {
      this.onClose()
    }
  }

  render() {
    const { children, bgColor, className, autoFocus, isFullScreen } = this.props
    const { ['data-testid']: testId } = this.props
    const { isShown } = this.state
    if (!isShown) {
      return null
    }
    return createPortal(
      <StyledPopup
        className={className}
        ref={node => {
          this.modalRef = node
        }}
        isFullScreen={isFullScreen}
        bgColor={bgColor}
        onKeyDown={this.handleKeyDown}
        onClick={this.handleClick}
        data-testid={testId || 'popup'}
        tabIndex={autoFocus ? '0' : ''}
        noMarginToAlign={this.props.noMarginToAlign || false}
      >
        <StyledPopupBody className="popup-body">{children}</StyledPopupBody>
      </StyledPopup>,
      document.getElementsByTagName('body')[0]
    )
  }
}

Popup.defaultProps = {
  autoFocus: true,
  disableScroll: true,
  isFullScreen: false,
}

export default Popup
