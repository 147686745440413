import React, { useEffect } from 'react'
import Router from 'next/router'
import { EventTrackingConsumer } from '../components/EventTrackingProvider'
import { GTM_ENV_VAR as GEV } from '../lib/eventTrackerFunction'
import { isStoreSyncEnabled } from '../utils/CartSyncUtils'
import extractUrl from '../lib/extractUrl'
/* selected filters & sort type are trackled
 * in EventTrackingProvider class
 */

function RouteChangeTracking(props) {
  const { track } = props

  useEffect(() => {
    const performTracking = () => {
      const event = {
        [GEV.EVENT]: 'Pageview',
        [GEV.URL]: extractUrl(),
      }

      const name = Router.router?.query?.exclude_ga_track_name
        ? decodeURIComponent(Router.router?.query?.exclude_ga_track_name)
        : ''

      if (Router.router?.route === '/categoryDetails') {
        event[[[GEV.CATEGORY_ID]]] = Router.router?.query?.exclude_ga_track_id
        event[[[GEV.CATEGORY_NAME]]] = name
      } else if (Router.router?.route === '/brandDetails') {
        event[[[GEV.BRAND_ID]]] = Router.router?.query?.exclude_ga_track_id
        event[[[GEV.BRAND_NAME]]] = name
      }

      track(event)
    }

    const handlePageLandingAndChange = () => {
      performTracking()
      isStoreSyncEnabled({ currentRoute: Router.router?.route })
    }

    handlePageLandingAndChange() // handles first page load cases

    // tracks on route change complete
    Router.events.on('routeChangeComplete', handlePageLandingAndChange)

    // Clean up after this effect:
    return function cleanup() {
      Router.events.off('routeChangeComplete', handlePageLandingAndChange)
    }
  }, [track])
  return null
}

const RouteChangeTrackingWrap = props => (
  <EventTrackingConsumer>
    {({ track }) => <RouteChangeTracking {...props} track={track} />}
  </EventTrackingConsumer>
)

export default RouteChangeTrackingWrap
