const routes = require('next-routes')

module.exports = routes()
  .add('product', '/product/:slug', 'productDetails')
  .add('review', '/review/:slug', 'productReview')
  .add('account', '/accounts', 'accounts')
  .add('tag', '/tag/:slug', 'tagDetails')
  .add('category', '/category/:slug', 'categoryDetails')
  .add('brands', '/brands', 'brands')
  .add('brand', '/brand/:slug', 'brandDetails')
  .add('categories', '/categories', 'categories')
  .add('checkout', '/checkout', 'checkout')
  .add('search', '/search', 'search')
  .add('page', '/page/:title', 'static-pages')
  .add('storeLocator', '/store-locator', 'store-locator')
  .add('shoppinglist', '/shoppinglist', 'shoppinglist')
  .add('shoppingListItem', '/shoppinglist/:id', 'shoppinglist/details')
  .add('order', '/orders/:id', 'order')
  .add('orders', '/orders', 'orders')
  .add('orderSummary', '/order-summary/:id', 'order-summary')
  .add('cart', '/cart', 'cart')
  .add('promotions', '/promotions', 'promotions')
  .add('external', '/external', 'external')
  .add('jwc', '/loyalty/programmes/jwc', 'loyalty/programmes/jwc')
  .add('loyalty-pay', '/loyalty/memberships/pay', 'loyalty/memberships/pay')
  .add('product-listing', '/product-listing', 'product-listing')
  .add('store-weekly-ad', '/weekly-promotions', '/weekly-promotions')
  .add('weekly-promos', '/swa/:slug', 'swa/weekly-promos')
  .add('invoice', '/orders/:id/invoice', 'empty-invoice')
  .add('vouchers', '/vouchers/:slug', 'voucher')
  .add('vouchersv2', '/voucher/:slug', 'voucher')
  .add('ld')
  .add('cancelOrder', '/orders/:id/cancel', 'orderCancellation')
  .add(
    'membershipRegistration',
    '/membership/registration',
    'membership/registration'
  )
  .add('membershipLinkage', '/membership/linkage', 'membership/linkage')
  .add('membershipCheckout', '/membership/checkout', 'membership/checkout')
  .add('membershipWelcome', '/membership/welcome', 'membership/welcome')
  .add('redirect', '/redirect', 'redirect')
  .add('pcm', '/pcm', 'index')
  .add('fpmLogin', '/fpm', 'fpm')
  .add('fpmProfile', '/fpm/profile', 'fpm/profile')
  .add('preauthCard', '/preauth-card', 'preauthCard')
  .add('business', '/business', 'business')
  .add('business-about-us', '/business-about-us', 'business-about-us')
  .add('reviewterms', '/reviewterms', 'reviewterms')
  .add('exclusiveVouchers', '/exclusive-christmas-vouchers', 'inStoreVouchers')
  .add('everydaySave', '/save-everyday-2024', 'everydaySave')
  .add('managePaymentsLink', '/managePaymentsLink', 'manage-payments-link')
