/* eslint-disable no-console */

import React from 'react'
import nookies from 'nookies'
import _includes from 'lodash/includes'
import Toast from '@ntuctech/devex-tangram/Toast'

import NdsThemeProvider from '@ntuctech/devex-tangram/Theme/NdsThemeProvider'

import routeController from '../../routes'

import {
  updatePreferences,
  deleteAuthoMetadata,
} from '../../lib/identity-sdk-api'
import {
  AUTH_TOKEN_KEY,
  FAIRPRICE_CLAIM_KEY,
  FORCE_REFRESH_TOKEN_URI,
} from '../../utils/constants'
import { decodeClaim } from '../../utils/decodeClaim'

import { AccountConsumer } from '../AccountProvider/AccountProvider'
import GmcPopup from './GmcPopup'
import LinkMergingPopup from '../Popup/LinkMergingPopup'
import { getCurrentHost } from '../../utils/Utils'
import { get, headers } from '../../lib/fetch'
import LinkMergingPopupPartial from '../Popup/LinkMergingPopupPartial'
import { GTM_ENV_VAR } from '../../lib/eventTrackerFunction'
import { AMP_EVENT_NAME } from '../../lib/amplitude'
import { EventTrackingConsumer } from '../EventTrackingProvider/EventTrackingProvider'

const WELCOME_MESSAGE_TIMEOUT_IN_MS = 4000
const matchingConfidenceClaim =
  'https://claims.identity.nedigital.sg/link_account_matching_confidence'

const refreshAuthToken = async (callback, setLoading) => {
  get(`${getCurrentHost()}/${FORCE_REFRESH_TOKEN_URI}`, {
    headers: headers(),
  })
    .then(() => {
      setLoading(false)
      callback()
    })
    .catch(err => {
      setLoading(false)
      return err
    })
}

const handleDeleteAuthMatchingConfidence = setOpenMergingPopup => {
  deleteAuthoMetadata('link_account_matching_confidence')
    .then(data => {
      if (data.message === 'OK') {
        setOpenMergingPopup(true)
      }
    })
    .catch(err => err)
}

const WelcomeProfileUpdateInner = props => {
  const { accountData, provisionB2bUser, track } = props
  const { [AUTH_TOKEN_KEY]: authToken, [FAIRPRICE_CLAIM_KEY]: fpClaim } =
    nookies.get() || {}

  const isProfileNew = decodeClaim(
    fpClaim,
    decodedToken =>
      !decodedToken[
        'https://claims.identity.nedigital.sg/association/fairprice'
      ]
  )

  const isUserOptInGmcFromSso = decodeClaim(
    fpClaim,
    decodedToken =>
      decodedToken[
        'https://claims.identity.nedigital.sg/preference/marketing_consent'
      ] === true
  )

  const invalidMatchingStatus = ['confident']
  // check if matching status is not within the array (matchingStatus === true)
  const matchingStatus = decodeClaim(
    authToken,
    decodedToken =>
      decodedToken[matchingConfidenceClaim] &&
      !invalidMatchingStatus.includes(decodedToken[matchingConfidenceClaim])
  )

  // get matching confidence claim value
  const isMatchTo = decodeClaim(
    authToken,
    decodedToken => decodedToken[matchingConfidenceClaim]
  )

  const shouldRenderGmcPopup = isProfileNew && !isUserOptInGmcFromSso
  const [renderGmcPopup, setRenderGmcPopup] =
    React.useState(shouldRenderGmcPopup)
  const [renderGreeting, setRenderGreeting] = React.useState(false)
  const [openMergingPopup, setOpenMergingPopup] = React.useState(false)
  const [openPartialMergingPopup, setOpenPartialMergingPopup] =
    React.useState(false)

  const showGreeting = () => {
    setRenderGreeting(true)
  }
  React.useEffect(() => {
    let timer
    if (renderGreeting) {
      timer = window.setTimeout(() => {
        setRenderGreeting(false)
        // remove fp_claim cookie
        fetch('/complete-signup')
      }, WELCOME_MESSAGE_TIMEOUT_IN_MS)
    }

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [renderGreeting])

  const isCurrentRouteNotIn = React.useCallback(invalidPathArr => {
    let routeName
    try {
      const path = routeController.Router.asPath

      routeName = routeController.match(path).route.name
    } catch {
      // do nothing
    }

    return !_includes(invalidPathArr, routeName)
  }, [])

  const showNudgePopup =
    matchingStatus && isCurrentRouteNotIn(['cart', 'checkout'])

  const performPostGmcActions = () => {
    showGreeting()
    // provision B2B user so that the preference values are updated
    provisionB2bUser(accountData)
  }

  const handleGmcSubmit = value => {
    updatePreferences(value)
      .then(() => {
        setRenderGmcPopup(false)
        performPostGmcActions()
      })
      .catch(e => e)
  }

  const isFromPaymentFlow = !isCurrentRouteNotIn(['checkout', 'cart'])
  const shouldTriggerSuppressed =
    isFromPaymentFlow && matchingStatus && isProfileNew

  React.useEffect(() => {
    // Suppress nudge flow if coming from payment flow.
    if (shouldTriggerSuppressed) {
      track({
        [GTM_ENV_VAR.AMPLITUDE_EVENT_NAME]: AMP_EVENT_NAME.NUDGE_SUPPRESSED,
        [GTM_ENV_VAR.EVENT_ACTION]: AMP_EVENT_NAME.NUDGE_SUPPRESSED,
        [GTM_ENV_VAR.MATCH_TYPE]: isMatchTo,
      })
    }
  }, [track, isMatchTo, shouldTriggerSuppressed])

  React.useEffect(() => {
    if (isProfileNew && showNudgePopup) {
      if (['phone', 'email'].includes(isMatchTo)) {
        handleDeleteAuthMatchingConfidence(setOpenPartialMergingPopup)
      } else if (isMatchTo === 'no_match') {
        handleDeleteAuthMatchingConfidence(setOpenMergingPopup)
      }
    }

    if (isProfileNew && isCurrentRouteNotIn(['cart'])) {
      if (!shouldRenderGmcPopup) {
        // user consent is already YES
        performPostGmcActions()
      }
    } else {
      provisionB2bUser(accountData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const greetingComp = React.useMemo(() => {
    if (!renderGreeting) {
      return null
    }

    const helloMsg = `Hello ${
      accountData.name || accountData.email
    }, welcome to FairPrice!`
    return <Toast inline message={helloMsg} data-testid="welcome-message" />
  }, [renderGreeting, accountData])

  /* render */
  if (!isProfileNew || !isCurrentRouteNotIn(['cart'])) {
    return null
  }
  if (showNudgePopup && ['phone', 'email'].includes(isMatchTo)) {
    return (
      <LinkMergingPopupPartial
        track={track}
        openPopup={openPartialMergingPopup}
        handleClosePopup={() => setOpenPartialMergingPopup(false)}
        handleRefreshAuthToken={refreshAuthToken}
        isMatchTo={isMatchTo}
        matchDetail={
          isMatchTo === 'phone'
            ? `+${accountData?.country_code} ${accountData?.phone_number}`
            : accountData?.email
        }
      />
    )
  }
  if (showNudgePopup) {
    return (
      <LinkMergingPopup
        track={track}
        isMatchTo={isMatchTo}
        openPopup={openMergingPopup}
        handleClosePopup={() => setOpenMergingPopup(false)}
        handleRefreshAuthToken={refreshAuthToken}
      />
    )
  }
  if (renderGmcPopup) {
    return <GmcPopup handleGmcSubmit={handleGmcSubmit} />
  } else {
    return greetingComp
  }
}

export const WelcomeProfileUpdate = props => (
  <NdsThemeProvider>
    <AccountConsumer>
      {({ accountData, provisionPartialB2bUser, isLoggedIn }) =>
        isLoggedIn ? (
          <EventTrackingConsumer>
            {({ track }) => (
              <WelcomeProfileUpdateInner
                {...props}
                track={track}
                accountData={accountData}
                provisionB2bUser={provisionPartialB2bUser}
              />
            )}
          </EventTrackingConsumer>
        ) : null
      }
    </AccountConsumer>
  </NdsThemeProvider>
)

export default WelcomeProfileUpdate
