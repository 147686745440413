import format from 'date-fns/format'
import isTomorrow from 'date-fns/is_tomorrow'
import isToday from 'date-fns/is_today'
import addDays from 'date-fns/add_days'
import { objectValues } from '../lib/polyfills'
import { PICKUP_MODE } from '../components/CheckoutAddressProvider/CheckoutAddressProvider'
import { pubSubEvent } from './pubSub'

const isStoreSyncEnabled = ({ currentRoute }) => {
  const routesToSyncCart = [
    '/promotions',
    '/brandDetails',
    '/categoryDetails',
    '/search',
    '/',
    '/product-listing',
    '/tagDetails',
    '/cart',
    '/checkout',
  ]

  if (routesToSyncCart.includes(currentRoute)) {
    pubSubEvent.publish('triggerStoreSync', true)
  }
}

const formatRecentSlotDetails = ({
  slots = {},
  currentPage,
  deliverySlotConfig,
}) => {
  const dontShowAvavilableSlotPageList = ['home']
  const allSlots =
    slots &&
    objectValues(slots).reduce((flattenSlots, slotsForTheDay) => {
      return [...flattenSlots, ...slotsForTheDay]
    }, [])

  let recentSlot = {}
  for (let i = 0; i < allSlots.length; i++) {
    if (allSlots[i] && allSlots[i].available) {
      recentSlot = allSlots[i]
      break
    }
  }

  if (
    Object.keys(recentSlot).length > 0 &&
    dontShowAvavilableSlotPageList.includes(currentPage)
  ) {
    return { shouldShow: false }
  } else if (Object.keys(recentSlot).length === 0) {
    return {
      text:
        deliverySlotConfig.isEnabledOnCartPage === '1' ||
        deliverySlotConfig.isEnabledOnHomePage === '1'
          ? deliverySlotConfig.displayText
          : 'Unable to get earliest timeslot. Please try again.',
      available: false,
      isPositive: false,
      shouldShow: true,
    }
  }

  if (recentSlot && recentSlot.startTime) {
    const isPickUp = recentSlot.orderType === PICKUP_MODE.toUpperCase()
    const isRecentSlotToday = isToday(recentSlot.startTime)
    const modifierText = isPickUp
      ? format(recentSlot.startTime, 'ha').toLocaleUpperCase()
      : recentSlot.text
    const text = isRecentSlotToday
      ? `Today, ${modifierText}`
      : isTomorrow(recentSlot.startTime)
      ? `Tomorrow, ${modifierText}`
      : `${format(recentSlot.startTime, 'D MMM')}, ${modifierText}`
    recentSlot.text = text
    if (isPickUp) {
      const endModifierText = isRecentSlotToday
        ? ` - Tomorrow ${format(
            recentSlot.startTime,
            'ha'
          ).toLocaleUpperCase()}`
        : ` - ${format(addDays(recentSlot.startTime, 1), 'D MMM')} ${format(
            recentSlot.startTime,
            'ha'
          ).toLocaleUpperCase()}`
      recentSlot.text += endModifierText
    }
    recentSlot.shouldShow = true
    recentSlot.isPositive = true
  }

  return recentSlot
}

const calculateDeliverySlot = (latestSlotTime, isBulk) => {
  if (isBulk && latestSlotTime?.isPositive) {
    const availableSlot = new Date(latestSlotTime.startTime)
    const slot =
      (latestSlotTime?.text && latestSlotTime.text.split(',')[1]) || ''
    return `${format(availableSlot, 'D MMM,  ddd')},${slot}`
  }
  return latestSlotTime?.text
}

module.exports = {
  isStoreSyncEnabled,
  formatRecentSlotDetails,
  calculateDeliverySlot,
}
