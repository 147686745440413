import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { trackRouteHistory } from '../../lib/utils'

export default function AppRouteTracker() {
  const router = useRouter()

  useEffect(() => {
    trackRouteHistory(router.route)
  }, [router.route])

  return null
}
