export const pubSubEvent = {
  events: [],
  subscribe: function(eventName, fn) {
    if (!this.events[eventName] && typeof fn === 'function') {
      this.events[eventName] = fn
    }
  },
  publish: function(eventName, data) {
    if (this.events[eventName]) {
      this.events[eventName](data)
    }
  },
}

export const PUB_SUB_EVENTS = {
  PERF_METRIC: 'PERF_METRIC',
  ADDRESS_CHANGED: 'ADDRESS_CHANGED',
}
