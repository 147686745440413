import { getUserType as getShoppingMode } from './userType'
import { patch, headers } from '../lib/fetch'

const getBusinessProfileState = props => {
  const isUserInB2bShoppingMode = getShoppingMode() === 'B2B'
  const businessProfileStatus = props.accountData?.business?.status // default: 'NONE'
  const businessProfile = props.accountData?.business?.profile // default: undefined
  const isBusinessProfileFull = businessProfileStatus === 'FULL'
  const canUserAccessBusinessProfile =
    isBusinessProfileFull || isUserInB2bShoppingMode
  const showCompleteB2bProfileReminder =
    isUserInB2bShoppingMode &&
    (!businessProfile?.uen || !businessProfile?.industry)

  const shouldProvisionPartialB2bUser =
    isUserInB2bShoppingMode && businessProfileStatus === 'NONE'

  return {
    canUserAccessBusinessProfile,
    businessProfile,
    showCompleteB2bProfileReminder,
    shouldProvisionPartialB2bUser,
  }
}

export const provisionB2bUser = () => {
  patch('profile/preferences', {
    headers: headers(),
    body: JSON.stringify({ 'business-customer': true }),
  })
}

export default getBusinessProfileState
