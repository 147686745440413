import React from 'react'
import styled, { keyframes } from 'styled-components'
import { disableScrollEvent, enableScrollEvent } from '../../lib/utils'

const spin = keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
`

const Loader = styled.div`
  border-radius: 50%;
  width: ${({ size }) => size || '1.875rem'};
  height: ${({ size }) => size || '1.875rem'};
  margin: auto;
  border: 0.25rem solid #ccc;
  border-top-color: blue;
  animation: ${spin} 500ms infinite linear;
`

const StyledLoader = styled(Loader)`
  position: fixed;
  left: 49%;
  top: 37%;
  margin: 0;
`

const LoaderContainer = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: ${({ zIndex }) => zIndex};
  position: fixed;
  background-color: rgba(51, 51, 51, 0.8);
`

export class GlobalLoader extends React.Component {
  static defaultProps = {
    zIndex: 1000000,
  }

  componentDidMount() {
    disableScrollEvent()
  }

  componentWillUnmount() {
    enableScrollEvent()
  }

  render() {
    const { zIndex } = this.props
    return (
      <LoaderContainer zIndex={zIndex} data-testid="global-loader">
        <StyledLoader />
      </LoaderContainer>
    )
  }
}

export default Loader
