class SplitConfigAdapter {
  unparseValue = value => {
    return {
      treatment: value.isEnabled ? 'on' : 'off',
      config: JSON.stringify(value.config || {}),
    }
  }

  unparseValues = values => {
    return Object.fromEntries(
      Object.entries(values).map(([key, value]) => {
        return [key, this.unparseValue(value)]
      })
    )
  }

  isConfigEnabled = (rawConfig, options) => {
    const enabledFlags = options?.flagOnValue
      ? [options?.flagOnValue]
      : ['on', '1']
    return enabledFlags.includes(rawConfig?.treatment)
  }

  parseConfigProperty = rawConfig => {
    try {
      return JSON.parse(rawConfig?.config || '{}')
    } catch {
      // silent error return empty object
      return {}
    }
  }
}

module.exports = {
  SplitConfigAdapter,
}
