let instance = null

//In anonymous mode of browsers, we are unable to save data in sessionStorage
//so we need to replicate sessionStorage and keep all changes in local data
class SessionStorageService {
  constructor() {
    if (!instance) {
      instance = this
    }

    return instance
  }

  currentSessionStorage = {}

  isSupported = () => {
    try {
      const key = '__this_key_should_not_use_anywhere__'
      const value = '__this_value_should_not_use_anywhere__'
      if (typeof window === 'undefined' || !window.sessionStorage) {
        return false
      }
      window.sessionStorage.setItem(key, value)
      window.sessionStorage.removeItem(key)
      return true
    } catch (e) {
      return false
    }
  }

  getItem = key => {
    if (this.isSupported()) {
      return window.sessionStorage.getItem(key)
    }
    return this.currentSessionStorage[key]
  }

  setItem = (key, value) => {
    if (this.isSupported()) {
      window.sessionStorage.setItem(key, value)
    }
    this.currentSessionStorage[key] = value
  }

  removeItem = key => {
    if (this.isSupported()) {
      window.sessionStorage.removeItem(key)
    }
    delete this.currentSessionStorage[key]
  }

  clear = () => {
    if (this.isSupported()) {
      window.sessionStorage.clear()
    }
    this.currentSessionStorage = {}
  }

  length = () => {
    if (this.isSupported()) {
      return window.sessionStorage.length
    }
    return Object.keys(this.currentSessionStorage).length
  }
}

export default new SessionStorageService()
