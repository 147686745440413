import LocalStorageService from '../../utils/LocalStorageService'
import { getUserType } from '../../utils/userType'

//we are having 3 basic states for store switches: FFS -> FFS, PFC -> FFS and FFS -> PFC
export const FFSFFS = 'FFSFFS'
export const PFCFFS = 'PFCFFS'
export const FFSPFC = 'FFSPFC'

export const returnItemCountFromDropOffState = (items, itemsToDropOff) => {
  if (!items || !Object.keys(items).length) {
    return {}
  }

  let newItems = { ...items }

  for (const itemId of Object.keys(newItems)) {
    const droppedOffItem = itemsToDropOff.find(item => `${item.id}` === itemId)

    if (droppedOffItem && droppedOffItem.oldQ) {
      const minCount = Math.min(droppedOffItem.oldQ, newItems[itemId].count)
      newItems = {
        ...newItems,
        [itemId]: {
          ...newItems[itemId],
          count: minCount,
        },
      }
    }
  }

  return newItems
}

export const getTimeStamp = () => {
  return Math.floor(Date.now() / 1000)
}

export const mergeUnavailableItemsToItems = (items, unavailableItems) => {
  let newItems = { ...items }

  for (const unavailableItem of unavailableItems) {
    const product = unavailableItem?.product || {}
    const productAdIds = product?.adId ? [product.adId] : []
    newItems = {
      ...newItems,
      [unavailableItem.id]: {
        ...product,
        id: unavailableItem.id,
        count: unavailableItem.wantQuantity,
        t: getTimeStamp(),
        adIds: productAdIds,
      },
    }
  }

  return newItems
}

const returnMinimumDefinedThreshold = (
  bulkOrderThreshold,
  storeBulkOrderThreshold
) => {
  if (bulkOrderThreshold && storeBulkOrderThreshold) {
    return Math.min(bulkOrderThreshold, storeBulkOrderThreshold)
  }
  if (bulkOrderThreshold && !storeBulkOrderThreshold) {
    return bulkOrderThreshold
  }
  if (!bulkOrderThreshold && storeBulkOrderThreshold) {
    return storeBulkOrderThreshold
  }

  return undefined
}

export const modifyBulkItemsBasedOnPfcAvailability = (items, changes) => {
  items = (items || [])
    .map(item => {
      let qty = Number(item.q)
      if (item && item.product) {
        const bulkOrderThreshold = Number(item.product.bulkOrderThreshold) || 0
        let soh = Number(item.product.storeSpecificData?.stock) || 0
        const storeBulkOrderThreshold =
          Number(item.product.stockOverride?.storeBulkOrderThreshold) || 0

        if (!soh && item.product.storeSpecificData?.unlimitedStock) {
          soh = 1
        }

        const bulkQty = returnMinimumDefinedThreshold(
          bulkOrderThreshold,
          storeBulkOrderThreshold
        )

        if (bulkQty && qty >= bulkQty) {
          qty = Math.min(soh, bulkQty - 1)

          if (!changes) {
            changes = []
          }
          changes = [
            ...changes,
            {
              oldQ: item.q,
              newQ: qty,
              product: item.product,
              reason: { dropOff: true },
            },
          ]
        }
      }
      return { ...item, q: qty }
    })
    .filter(item => item.q)

  return { items, changes }
}

export const determineSwitchedStoreType = (
  oldCheckoutAddress,
  newCheckoutAddress,
  pfcStoreId
) => {
  const fromPFC = oldCheckoutAddress.storeId === pfcStoreId
  const toPFC = newCheckoutAddress.storeId === pfcStoreId

  if (!fromPFC && !toPFC) {
    return FFSFFS
  }
  if (fromPFC && !toPFC) {
    return PFCFFS
  }
  if (!fromPFC && toPFC) {
    return FFSPFC
  }

  return ''
}

export const modifyLocalItemsAccordingToLoginState = (
  items,
  sellersItems,
  isLoggedIn
) => {
  const localItems = { ...items }
  for (const key in localItems) {
    if (localItems[key].count === 0) {
      delete localItems[key]
    }
  }
  const isB2BUser = getUserType() === 'B2B'
  //only set local cart items for guest users
  if (!isLoggedIn) {
    LocalStorageService.setItem(
      isB2BUser ? 'b2bCart' : 'cart',
      JSON.stringify(localItems)
    )
    LocalStorageService.setItem(
      isB2BUser ? 'b2bSellerCart' : 'sellerCart',
      JSON.stringify(sellersItems)
    )
  } else {
    //if it's a logged-in user, we can remove local cart to avoid unexpected cart sync
    LocalStorageService.removeItem(isB2BUser ? 'b2bCart' : 'cart')
    LocalStorageService.removeItem(isB2BUser ? 'b2bSellerCart' : 'sellerCart')
  }
}

const makeArray = item => (Array.isArray(item) ? item : [item])

export const getCartFromResponse = items => {
  const time = getTimeStamp()
  items = items.reduce((acc, item, index) => {
    if (item.product) {
      const product = {
        ...item,
        ...item.product,
        __server__handlingDays: item.handlingDays,
        isFree: item.mrp === 0 || item.isFree,
        isChecked: item.isChecked === undefined ? true : item.isChecked,
        offers: item.offers,
        offersUnApplied: item.offersUnApplied,
        __serverIndex__: index,
      }
      product.count = Number(item.q)
      product.storeSpecificData = product.storeSpecificData && [
        product.storeSpecificData,
      ]
      if (!product.t) {
        product.t = time
      }

      product.totalDiscount = 0
      if (product.count && product.discount) {
        product.totalDiscount = product.count * product.discount
      }

      // filtering out the free products
      if (!acc.hasOwnProperty(product.id)) {
        acc[item.id] = product
      } else {
        acc[item.id].isChecked = item.isChecked
        acc[item.id].count += Number(item.q)
        acc[item.id].totalDiscount += product.totalDiscount
        acc[item.id].discount += product.discount

        if (product.adIds) {
          const currentAdIds = acc[item.id].adIds || []
          acc[item.id].adIds = [
            ...makeArray(currentAdIds),
            ...makeArray(product.adIds),
          ]
        }
        if (product.offers) {
          const totalOffers = acc[item.id].offers || []
          acc[item.id].offers = [
            ...makeArray(totalOffers),
            ...makeArray(product.offers || []),
          ]
        }
        if (product.offersUnApplied) {
          const totalOffersUnApplied = acc[item.id].offersUnApplied || []
          acc[item.id].offersUnApplied = [
            ...makeArray(totalOffersUnApplied),
            ...makeArray(product.offersUnApplied || []),
          ]
        }
      }
    }
    return acc
  }, {})

  return items
}

export const allEqualCounts = (oldItems, newItems) => {
  return Object.keys(oldItems).every(
    key =>
      oldItems[key] &&
      newItems[key] &&
      oldItems[key].count === newItems[key].count
  )
}

export const totalProductCount = (items = {}) => {
  return Object.keys(items).reduce((total, item) => {
    // this seems wrong :| just keep it for now
    if (item.isFree) {
      return total
    }

    total += items[item].count
    return total
  }, 0)
}

export const findBulkOrderProducts = (items, leadTime) => {
  return items
    .filter(
      item =>
        item &&
        item.product &&
        Number(
          item.product.storeSpecificData && item.product.storeSpecificData.mrp
        ) > 0 &&
        item.product.bulkOrderThreshold <= Number(item.q)
    )
    .map(item => ({
      ...item,
      product: { ...item.product, leadTime },
    }))
}

export const consolidateSellerItemData = (sellersItems = []) => {
  if (!sellersItems) {
    return []
  }
  return sellersItems.map(seller => ({
    ...seller,
    items: getCartFromResponse(seller.items),
  }))
}

export const isIncluded = (arr, id) => {
  return arr?.findIndex(item => item && item.id === id) > -1
}

export const mergeAllSimilarDroppedOffItems = droppedOffItems => {
  if (!droppedOffItems || !droppedOffItems.length) {
    return droppedOffItems
  }

  const groupedDroppedOffItemsById = droppedOffItems.reduce(
    (returnedValue, currentItem) => {
      returnedValue[currentItem.id] = returnedValue[currentItem.id] || []
      returnedValue[currentItem.id].push(currentItem)
      return returnedValue
    },
    {}
  )

  const groupedDroppedOffItemKey = Object.keys(groupedDroppedOffItemsById)

  if (
    !groupedDroppedOffItemKey.length ||
    groupedDroppedOffItemKey.every(
      key => groupedDroppedOffItemsById[key].length <= 1
    )
  ) {
    return droppedOffItems
  }

  const newDroppedItems = []
  for (let i = 0; i < groupedDroppedOffItemKey.length; i++) {
    const key = groupedDroppedOffItemKey[i]
    const groupedList = groupedDroppedOffItemsById[key]

    if (!groupedList || !groupedList.length) {
      continue
    }

    const firstItemInGroupedList = { ...groupedList[0] }

    const allNewQ = groupedList.map(item => item.newQ)
    firstItemInGroupedList.newQ = Math.min(...allNewQ)

    const allOldQ = groupedList.map(item => item.oldQ)
    firstItemInGroupedList.oldQ = Math.max(...allOldQ)

    newDroppedItems.push(firstItemInGroupedList)
  }

  return newDroppedItems
}

export const appliedOffers = (items = []) => {
  // Filter the products with offer and then create an array of unique offers
  const offers = items
    .filter(item => item.offers && item.offers.length && item.mrp)
    .reduce((acc, item) => {
      item.offers.forEach(offer => {
        if (!isIncluded(acc, offer.id)) {
          acc = [...acc, offer]
        }
      })
      return acc
    }, [])

  // Get free gift offer id
  const freeGiftOffers = items
    .filter(item => item.offers && item.offers.length && item.isFree)
    .reduce((acc, item) => {
      if (!acc.includes(item.offers[0].id)) {
        acc = [...acc, item.offers[0].id]
      }
      return acc
    }, [])

  return offers.filter(offer => !freeGiftOffers.includes(offer.id))
}

export const findOtherOffers = (
  syncedItems,
  offerObj,
  cartOffers,
  additionalCharges
) => {
  const offers = appliedOffers(syncedItems)
  const jwcOffer = []
  const pwpOffer = []
  const otherOffers = []

  const { minPurchasePWP } = offerObj || {}
  const orderAmount = additionalCharges?.orderAmount || 0

  let isPwpApplicable = orderAmount >= minPurchasePWP

  const allOffers = offers.map(offer => ({
    ...offer,
    productsBought: syncedItems
      .filter(item => item.offers && isIncluded(item.offers, offer.id))
      .map(item => ({
        ...item,
        offers: item.offers.filter(({ id }) => id === offer.id),
      }))
      .reduce(
        (acc, item) => [
          ...acc,
          ...Array(Number(item.q)).fill({
            ...item.product,
            mrp: item.mrp,
            discount: item.offers[0].discount,
            offers: item.offers,
          }),
        ],
        []
      ),
  }))

  allOffers.forEach(offer => {
    if (
      offer?.details?.ruleDetail?.buy?.tags?.findIndex(
        tag => tag.name === 'jwc'
      ) > -1
    ) {
      jwcOffer.push(offer)
      return
    }

    const isPwp =
      offer?.details?.ruleDetail?.buyExcluding?.tags?.findIndex(
        tag => tag.name === 'pwp'
      ) > -1
    if (isPwp) {
      pwpOffer.push(offer)
    }
  })

  cartOffers?.forEach(
    ({ cartDiscount, offer, id, discount, shippingDiscount }) => {
      if (offer) {
        offerObj = {
          discount: cartDiscount || discount || shippingDiscount,
          details: offer,
          id,
        }
        otherOffers.push(offerObj)
      }
    }
  )

  if (pwpOffer.length) {
    isPwpApplicable = true
  }

  return { jwcOffer, pwpOffer, otherOffers, isPwpApplicable }
}

const isRemovedInCartRequest = (cartReq, p) => {
  return (
    cartReq?.cart?.items?.findIndex(
      item => item.id === p.id && item.q === '0'
    ) > -1
  )
}

const getChangeItem = (change, items) => {
  const changeItem = (items || []).find(item => change.id === item.id)
  const reason = changeItem ? changeItem.reason : 'OutOfStock'
  return {
    product: change.product || {},
    ...(changeItem || {}),
    reason,
    id: change.id,
    newQ: change.newQ,
    oldQ: change.oldQ,
  }
}

const isPoaDropoffItem = reason => {
  return [
    'NotApplicable',
    'PreOrder',
    'MaxPurchasableQuantityReached',
  ].includes(reason)
}

export const findDropOff = (changes, cartReq, items) => {
  if (changes?.length > 0) {
    // filter changes which has newQ as 0, oldQ as +ve, not present in items list and not present in cart req with q 0 && is not a free gift
    return changes
      .filter(p => {
        const dropOffItem = getChangeItem(p, items)
        return (
          p.reason &&
          p.reason.dropOff &&
          !isRemovedInCartRequest(cartReq, p) &&
          !isPoaDropoffItem(dropOffItem.reason) &&
          p.product &&
          p.product.storeSpecificData &&
          Number(p.product.storeSpecificData.mrp)
        )
      })
      .map(p => {
        const { product, ...others } = p
        return {
          ...product,
          ...others,
        }
      })
  }

  return []
}

export const getLastCartWithNewTimeStamp = cart => {
  const t = getTimeStamp()
  return cart
    ? Object.keys(cart)
        .map(key => ({ ...cart[key], t }))
        .reduce((acc, item) => ({ ...acc, [item.id]: item }), {})
    : {}
}

export const findPOAInapplicableItems = (changes, items) => {
  const inapplicableItems = []
  for (const change of changes || []) {
    if (
      !change.reason?.dropOff ||
      !Number(change.product?.storeSpecificData?.mrp)
    ) {
      continue
    }

    const inapplicableItem = getChangeItem(change, items)

    if (isPoaDropoffItem(inapplicableItem.reason)) {
      inapplicableItems.push(inapplicableItem)
    }
  }

  return inapplicableItems
}

export const extractAmendableDeliveryOrders = cart => {
  return (cart.amendableDeliveryOrders || []).map(
    ({ deliveryAddress, ...order }) => ({
      ...order,
      address: {
        id: deliveryAddress?.id || cart.addressId,
        ...(deliveryAddress || {}),
      },
    })
  )
}

export const getMaxAllowableQuantity = (product, wantQuantity) => {
  const maxPurchasableQuantity =
    product.stockOverride?.maxPurchasableStock || Infinity

  const isUnlimitedStock = Boolean(product.storeSpecificData[0].unlimitedStock)
  if (isUnlimitedStock) {
    return maxPurchasableQuantity
  }

  const bulkRoutingThreshold =
    product.bulkRoutingThreshold?.bulkThreshold ||
    product.storeSpecificData?.[0]?.bulkRoutingThreshold?.bulkThreshold ||
    Infinity
  if (wantQuantity >= bulkRoutingThreshold) {
    return maxPurchasableQuantity
  }

  const stockOnHand =
    product.storeSpecificData[0].stock === 0
      ? 0
      : product.storeSpecificData[0].stock || Infinity
  if (stockOnHand > maxPurchasableQuantity) {
    return maxPurchasableQuantity
  }

  return stockOnHand
}
