export const getPromoRemovedLabel = ({
  isVoucherRemovalBannerEnabled,
  removedCouponDiscounts,
}) => {
  let promoRemovedLabel = 'Voucher has been removed'

  const removedCouponLength = removedCouponDiscounts.length

  if (removedCouponLength === 0 || !isVoucherRemovalBannerEnabled) {
    return promoRemovedLabel
  }

  const firstVoucherWithNameOrCode = removedCouponDiscounts.find(
    couponDiscount =>
      couponDiscount.coupon?.aliasName || couponDiscount.coupon?.couponCode
  )

  const firstVoucherNameOrCode =
    firstVoucherWithNameOrCode?.coupon?.aliasName ||
    firstVoucherWithNameOrCode?.coupon?.couponCode

  if (firstVoucherNameOrCode) {
    promoRemovedLabel = `“${firstVoucherNameOrCode}” ${
      removedCouponLength > 1
        ? `and ${removedCouponLength - 1} other voucher are`
        : 'is'
    } removed because conditions are not met.`
  }

  return promoRemovedLabel
}

export const getRemovedCoupons = ({
  cartCouponDiscounts,
  prevCartCouponDiscounts,
}) => {
  const currentCouponCode = (cartCouponDiscounts || []).map(
    cartCoupon => cartCoupon.coupon?.couponCode
  )
  return (prevCartCouponDiscounts || []).filter(
    couponDiscount =>
      !currentCouponCode.includes(couponDiscount.coupon?.couponCode)
  )
}
