import React from 'react'
import qs from 'query-string'
import { get, headers } from './../../lib/fetch'
import nookies from 'nookies'
import { AccountConsumer } from '../AccountProvider'
import { pubSubEvent, PUB_SUB_EVENTS } from '../../utils/pubSub'
export const CHECKOUT_ADDRESS = 'checkoutAddress'
export const B2B_CHECKOUT_ADDRESS = 'b2bCheckoutAddress'
export const TIMESLOT = 'TIMESLOT'
export const ONE_MONTH_IN_MS = 30 * 24 * 60 * 60
export const ONE_YEAR_IN_MS = 365 * 24 * 60 * 60
export const PICKUP_MODE = 'pickup'
export const HOME_DELIVERY_MODE = 'delivery'
import processAvailableStores from '../../lib/processAvailableStores'
import { getUserType } from '../../utils/userType'
import SprigService from '../../utils/SprigService'

const Context = React.createContext({
  checkoutAddress: {},
  update: () => {},
})

export const getServiceableAreaFor = async location => {
  if (typeof location !== 'object') {
    throw new ReferenceError(
      'getServiceableAreaFor expects an object as the only argument'
    )
  }

  const query = {
    ...location,
    city: 'Singapore',
    //these parameters are crucial to retain store id value
    availableStores: true,
    orderType: getUserType() === 'B2B' ? 'B2B' : 'DELIVERY',
  }

  const { data } = await get(`serviceable-area?${qs.stringify(query)}`, {
    headers: headers(),
  })

  if (!data || data.store === null) {
    throw new Error('AREA_NOT_SERVICEABLE')
  }

  return data
}

// Output is what is saved inside checkout provider.
// `address` is from `/address/search`
// `serviceableArea` is from `/serviceable-area`
export const mapHomeAddressAndServiceAreaToLocal = (
  address,
  serviceableArea
) => {
  const valuesFromServiceableArea = {
    deliveryFee: serviceableArea.deliveryFee,
    storeName: serviceableArea.store.name,
    storeId: serviceableArea.store.hasPicking
      ? serviceableArea.store.id
      : serviceableArea.store.pickingStoreId,
    pickingStoreId: serviceableArea.store.pickingStoreId,
  }

  return {
    pincode: address.postcode,
    address: address.postcode
      ? `${address.buildingNumber || ''}  ${
          address.streetName || ''
        }, Singapore  ${address.postcode || ''}`
      : '',
    location: {
      latitude: address.lat,
      longitude: address.long,
    },
    type: 'delivery',
    ...valuesFromServiceableArea,
  }
}

// Output is what is saved inside checkout provider.
// `savedAddress` is from `/me` OR `/api/address`
// `serviceableArea` is from `/serviceable-area`
export const mapSavedHomeAddressAndServiceAreaToLocal = (
  savedAddress,
  serviceableArea
) => {
  const valuesFromServiceableArea = {
    deliveryFee: serviceableArea.deliveryFee,
    storeId: serviceableArea.store.hasPicking
      ? serviceableArea.store.id
      : serviceableArea.store.pickingStoreId,
    storeName: serviceableArea.store.name,
    storeType: serviceableArea.store.storeType,
    availableStores: serviceableArea.availableStores,
    pickingStoreId: serviceableArea.store.pickingStoreId,
    clientId: serviceableArea.store.clientId,
  }

  return {
    ...savedAddress,
    type: 'delivery',
    ...valuesFromServiceableArea,
  }
}

class CheckoutAddressProvider extends React.Component {
  constructor(props) {
    super(props)
    const { defaultAddress } = props
    this.update = this.update.bind(this)
    this.checkIfUpdate = this.checkIfUpdate.bind(this)
    this.state = {
      checkoutAddress: defaultAddress,
      showPopup: false,
      update: this.update,
      updateBillingAddress: this.updateBillingAddress,
    }
  }

  componentDidMount() {
    this.checkIfUpdate(true)
  }

  async checkIfUpdate() {
    const { checkoutAddress } = this.state
    if (
      checkoutAddress &&
      checkoutAddress.address !== undefined &&
      checkoutAddress.availableStores === undefined
    ) {
      const { pincode, location } = checkoutAddress
      const query = {
        ...location,
        pincode,
        city: 'Singapore',
        availableStores: true,
        storeId: checkoutAddress.storeId,
        orderType: getUserType() === 'B2B' ? 'B2B' : 'DELIVERY',
      }

      const { data } = await get(`serviceable-area?${qs.stringify(query)}`, {
        headers: headers(),
      })
      this.update({
        pincode: pincode,
        address: checkoutAddress.address,
        location: {
          latitude: location.lat,
          longitude: location.long,
        },
        latitude: location.lat,
        longitude: location.long,
        type: getUserType() === 'B2B' ? 'B2B' : 'delivery',
        storeId: data.store.hasPicking
          ? data.store.id
          : data.store.pickingStoreId,
        deliveryFee: data.deliveryFee,
        storeName: data.store.name,
        storeType: data.store.storeType,
        availableStores: data.availableStores,
        pickingStoreId: data.store.pickingStoreId,
        building: checkoutAddress.buildingName,
        clientId: data.store.clientId,
      })
    }
  }

  async componentDidUpdate(prevProps) {
    // Update cache expiration when login status change.
    // If the user is a loggedIn user set cookie expiration time to ONE_YEAR_IN_MS(1 Year)
    // when user logouts set the cookie expiration time to ONE_MONTH_IN_MS(30 days)
    if (
      this.props.isLoggedIn !== prevProps.isLoggedIn &&
      this.state.checkoutAddress
    ) {
      const address = this.state.checkoutAddress
      if (address && address.type === 'delivery') {
        delete address.id
      }
      this.updateUserAddress(JSON.stringify(address))
    }
  }

  async update(checkoutAddress, syncCart, isAddressManuallyChanged) {
    // This is destructured for 2 reasons:
    // 1) Easier to see what is needed.
    // 2) We can safely ignore irrelevant parts of `checkoutAddress`
    // `syncCart` flag is used to inform CartProvider if cart needs
    // to be fetched again with new storeId when address is changed
    const {
      address,
      storeId,
      id,
      pincode,
      storeName,
      storeType,
      availableStores,
      location,
      pickingStoreId,
      type,
      fromTime,
      toTime,
      building,
      clientId,
    } = checkoutAddress
    const processedAvailableStores = processAvailableStores(availableStores)
    this.updateUserAddress({
      address,
      storeId,
      id,
      pincode,
      storeName,
      storeType,
      availableStores: processedAvailableStores,
      location,
      pickingStoreId,
      type,
      fromTime,
      toTime,
      building,
      clientId,
    })
    const oldCheckoutAddress = this.state.checkoutAddress
    const isAddedNewAddress = Boolean(
      !oldCheckoutAddress.address && checkoutAddress.address
    )

    return new Promise(resolve => {
      this.setState(
        {
          checkoutAddress,
          syncCart,
          isAddressManuallyChanged,
          isAddedNewAddress,
        },
        resolve
      )
    })
  }

  // If user is a logged in and change his address we should update the address.
  // Logic of comparison between the addresses will be taken care by the api.
  updateUserAddress(address) {
    const cookies = nookies.get()
    if (address) {
      SprigService.pushAttributes('SPRIG_PARAM_STORE_ID', address?.storeId)
      const options = {
        path: '/',
        maxAge: this.props.isLoggedIn ? ONE_YEAR_IN_MS : ONE_MONTH_IN_MS,
      }
      if (typeof address === 'object') {
        pubSubEvent.publish(PUB_SUB_EVENTS.ADDRESS_CHANGED, address)
        address = JSON.stringify(address)
      }
      nookies.set(
        {},
        getUserType() === 'B2B' ? B2B_CHECKOUT_ADDRESS : CHECKOUT_ADDRESS,
        address,
        options
      )
    } else {
      nookies.destroy({}, CHECKOUT_ADDRESS)
    }

    if (address) {
      let oldAddress = undefined
      if (cookies && cookies[CHECKOUT_ADDRESS]) {
        oldAddress = JSON.parse(cookies[CHECKOUT_ADDRESS])
      }
      const parsedAddress = address ? JSON.parse(address) : undefined
      if (oldAddress && oldAddress.id !== parsedAddress.id) {
        nookies.destroy({}, TIMESLOT)
      }
    }
  }

  updateBillingAddress = currentBillingAddress => {
    currentBillingAddress.isSelected = true
    nookies.set({}, 'billingAddress', JSON.stringify(currentBillingAddress))
  }

  render() {
    const { children } = this.props
    const { billingAddress } = nookies.get()
    return (
      <Context.Provider
        value={{
          ...this.state,
          billingAddress: JSON.parse(billingAddress || '{}'),
        }}
      >
        {children}
      </Context.Provider>
    )
  }
}

const CheckoutAddressProviderWrapper = props => (
  <AccountConsumer>
    {({ accountData, isLoggedIn }) => (
      <CheckoutAddressProvider
        {...props}
        accountData={accountData}
        isLoggedIn={isLoggedIn}
      />
    )}
  </AccountConsumer>
)

CheckoutAddressProviderWrapper.defaultProps = {
  defaultAddress: {},
}

export default CheckoutAddressProviderWrapper

export const CheckoutAddressConsumer = Context.Consumer
export { Context }
