import {
  GTM_ENV_VAR as GEV,
  PAGE_TYPES,
  fetchCategory,
} from '../../lib/eventTrackerFunction'
import { getFirstAvailableTimeslot } from '../../components/CheckoutForm/utils'
import { compact } from 'lodash'
import differenceInCalendarDays from 'date-fns/difference_in_calendar_days'
import differenceInHours from 'date-fns/difference_in_hours'
import { AMP_EVENT_NAME } from '../../lib/amplitude'
import format from 'date-fns/format'

const NO_TIMESLOT_DEFAULT = {
  daysToTimeslot: -1,
  hoursToTimeslot: -1,
  startTime: 'no slot',
}

export const getFirstAvailableTimeslotWithDefault = sellerTimeSlots => {
  return getFirstAvailableTimeslot(sellerTimeSlots?.slot) || NO_TIMESLOT_DEFAULT
}

export const getTrackingPayloadForItemLevel = (items, fulfillmentTypeMap) => {
  const productHoursToAvailSlot = []
  const productDaysToAvailSlot = []
  const productNames = []
  const sapProductIds = []
  const dbpProductIds = []
  const productOfferPrices = []
  const productRetailPrices = []
  const productQuantity = []
  const productPromotionTypes = []
  const productPromotionDescriptions = []
  const productFulTypes = []
  const cartLCategories = []
  const cartL1Categories = []
  const cartL2Categories = []
  const mkpItems = []
  const promotionCounts = []

  for (const item of items) {
    sapProductIds.push(item.clientItemId)
    dbpProductIds.push(item.id)
    productQuantity.push(item.count)
    productPromotionTypes.push(
      JSON.stringify(
        compact((item.offers || []).map(offer => offer?.details?.offerType))
      )
    )
    productPromotionDescriptions.push(
      JSON.stringify(
        compact((item.offers || []).map(offer => offer?.details?.description))
      )
    )
    promotionCounts.push((item.offers || []).length)
    const storeSpecific = item.storeSpecificData?.[0]
    let productRetailPrice = 0
    let productOfferPrice = 0
    if (storeSpecific) {
      const retailPrice = storeSpecific.mrp || 0
      productRetailPrice = (retailPrice * item.count).toFixed(2)
      productOfferPrice = (productRetailPrice - item.totalDiscount).toFixed(2)
    }
    productOfferPrices.push(productOfferPrice.toString())
    productRetailPrices.push(productRetailPrice.toString())
    const categoryList = fetchCategory(item.primaryCategory)
    cartLCategories.push(categoryList['product_category'])
    cartL1Categories.push(categoryList['product_category_l1'])
    cartL2Categories.push(categoryList['product_category_l2'])
    mkpItems.push(item.isMKP ? 'yes' : 'no')
    productNames.push(item.name)
    productHoursToAvailSlot.push(
      differenceInHours(new Date(item.deliveredBy), new Date()) || -1
    )
    productDaysToAvailSlot.push(
      differenceInCalendarDays(new Date(item.deliveredBy), new Date()) || -1
    )
    productFulTypes.push(fulfillmentTypeMap[item.id])
  }

  return {
    [GEV.AMPLITUDE_PRODUCT_FULFILMENT_TYPE]: productFulTypes,
    [GEV.AMPLITUDE_PRODUCT_NAMES]: productNames,

    [GEV.PROD_ID]: sapProductIds,
    [GEV.PRODUCT_ID_ZOP]: dbpProductIds,

    [GEV.OFFER_PRICE]: productOfferPrices,
    [GEV.RETAIL_PRICE]: productRetailPrices,
    [GEV.AMPLITUDE_PRODUCT_QUANTITY]: productQuantity,

    [GEV.PROMOTION_TYPES]: productPromotionTypes,
    [GEV.PROMOTION_DESCRIPTIONS]: productPromotionDescriptions,

    [GEV.PROD_CAT_L]: cartLCategories,
    [GEV.PROD_CAT_L1]: cartL1Categories,
    [GEV.PROD_CAT_L2]: cartL2Categories,

    [GEV.AMPLITUDE_IS_MKP_ITEMS]: mkpItems,

    [GEV.AMPLITUDE_PRODUCT_DAYS_TO_AVAILABLE_SLOT]: productDaysToAvailSlot,
    [GEV.AMPLITUDE_PRODUCT_HOURS_TO_AVAILABLE_SLOT]: productHoursToAvailSlot,

    [GEV.PROMOTION_COUNT]: promotionCounts,
  }
}

export const getAddRemoveCartMetaFromDelta = delta => {
  if (delta >= 1) {
    return {
      eventAction: 'AddToCart',
      eventCat: 'Ecommerce-Conversion',
      amplitudeEventName: AMP_EVENT_NAME.PRODUCT_ADDED_TO_CART,
    }
  } else {
    return {
      eventAction: 'RemoveFromCart',
      eventCat: 'Ecommerce-Engagement',
      amplitudeEventName: AMP_EVENT_NAME.PRODUCT_REMOVED_FROM_CART,
    }
  }
}

// format i.e. Tuesday, 22 Feb 2022, 9AM - 10PM
export const formatSegmentTimeslot = ({ startTime, endTime }) => {
  return `${format(new Date(startTime), 'dddd, DD MMM YYYY, ha')}-${format(
    new Date(endTime),
    'ha'
  )}`
}

const LIST_NAME_LOC_TYPE = {
  PRODUCT_LISTING: 'product listing',
  PRODUCT_WIDGET: 'product widget',
}

// ga4
export const getGa4ProductListName = (config, router) => {
  let locType = LIST_NAME_LOC_TYPE.PRODUCT_LISTING
  let name = config?.title || router?.query?.title || ''

  if (config?.loadMoreType === 'SEEALL') {
    locType = LIST_NAME_LOC_TYPE.PRODUCT_WIDGET
  }
  // product listing
  else {
    switch (config?.pageType) {
      case PAGE_TYPES.CATEGORY:
        name = 'category'
        break
      case PAGE_TYPES.BRAND:
        name = 'brand'
        break
      case PAGE_TYPES.PROMO:
        locType = 'offers'
        name = router?.query?.tag || 'all'
        break
      case PAGE_TYPES.TAG:
        name = 'tag'
        break
      case PAGE_TYPES.SEARCH:
        name = 'search'
        break
    }
    if (config.isShoppingListPage) {
      name = 'shopping list'
    }
  }

  return `${locType}-${name?.toLowerCase()}`
}
