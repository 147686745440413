import React from 'react'
import styled from 'styled-components'
import dynamic from 'next/dynamic'
const NotificationBar = dynamic(() => import('../NotificationBar'))
import { SCREEN_SIZE } from '../../lib/Media'
import BackIcon from '../icons/BlueBackIcon'
import Text from '../Text'
import Router from 'next/router'
import { EventTrackingConsumer } from '../EventTrackingProvider/EventTrackingProvider'
import { AMP_EVENT_NAME, getPageTypeByUrl } from '../../lib/amplitude'
import { getCtaLocationMeta } from '../../lib/utils'
import { GTM_ENV_VAR as GEV } from '../../lib/eventTrackerFunction'

const Container = styled.div`
  position: fixed;
  border-radius: 4px;
  background-color: transparent;
  top: 7rem;
  width: 100%;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 50px;

  ${SCREEN_SIZE.From.Tablet} {
    top: 7.4rem;
    right: 1.5rem;
    width: auto;
  }
`

const StyledBackIcon = styled(BackIcon)`
  transform: rotate(180deg);
  height: 1rem;
  width: 1rem;
  color: #a3c2f5;
`

class CartFeedbackInner extends React.Component {
  constructor(props) {
    super(props)
    this.handleViewCartClick = this.handleViewCartClick.bind(this)
    this.state = {
      feedback: props.feedback,
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.feedback.status &&
      prevProps.feedback !== this.props.feedback
    ) {
      this.setState(
        {
          feedback: {
            status: false,
          },
        },
        () => {
          this.setState({
            feedback: this.props.feedback,
          })
        }
      )
    }
  }

  handleViewCartClick() {
    this.props.track({
      [GEV.AMPLITUDE_EVENT_NAME]: AMP_EVENT_NAME.CART_CLICKED,
      [GEV.AMPLITUDE_CTA_LOCATION]: {
        pageName: getPageTypeByUrl(),
        pageMeta: getCtaLocationMeta() || [],
      },
    })
  }

  render() {
    const { onHide } = this.props
    const { feedback } = this.state
    const { message, hideViewCart } = feedback
    return (
      <Container>
        {feedback.status && (
          <NotificationBar
            message={message}
            onTimeout={onHide}
            onAction={() => {
              if (!hideViewCart) {
                this.handleViewCartClick()
                Router.pushRoute('/cart')
              }
            }}
            actionContent={
              !hideViewCart && (
                <React.Fragment>
                  <Text size="medium" weight="bold" color="#a3c2f5">
                    View cart
                  </Text>{' '}
                  <StyledBackIcon color="#a3c2f5" />
                </React.Fragment>
              )
            }
          />
        )}
      </Container>
    )
  }
}

const CartFeedback = props => (
  <EventTrackingConsumer>
    {({ track }) => <CartFeedbackInner {...props} track={track} />}
  </EventTrackingConsumer>
)

export default CartFeedback
