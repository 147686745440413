import React, { useEffect, useState, useCallback, useRef } from 'react'
import { getUserType } from '../../utils/userType'
import { AccountConsumer } from '../AccountProvider'
import { searchStoreByLocation } from '../../utils/checkoutUtils'
import { getConfigFor } from '../../utils/configService'
import { CheckoutAddressConsumer } from '../CheckoutAddressProvider/CheckoutAddressProvider'

import ButtonContained from '@ntuctech/devex-tangram/Button/ButtonContained'
import ButtonOutlined from '@ntuctech/devex-tangram/Button/ButtonOutlined'
import Modal from '@ntuctech/devex-tangram/Modal/Modal'
import ModalActions from '@ntuctech/devex-tangram/ModalActions/ModalActions'
import ModalContent from '@ntuctech/devex-tangram/ModalContent/ModalContent'
import ModalTitle from '@ntuctech/devex-tangram/ModalTitle/ModalTitle'
import Typography from '@ntuctech/devex-tangram/Typography/Typography'
import NdsThemeProvider from '@ntuctech/devex-tangram/Theme/NdsThemeProvider'
import UnapplicableProduct from '../UnapplicableProduct/UnapplicableProduct'
import { SPLIT_FEATURES } from '../../utils/constants'
import { useRouter } from 'next/router'
import ProductQuantityAdjustmentMessage from '../ProductQuantityAdjustmentMessage'
import { useSyncableSessionStorage } from './SyncableSessionStorage'

export const PostOrderAmendContext = React.createContext({
  isAmendEnabled: false,
  orderToAmend: null,
  isAmendFromCart: false,
  amendableOrders: [],
  unaplicableItems: [],
  /* eslint-disable no-unused-vars */
  setUnaplicableItems: items => {},
  setOrderToAmend: orderToAmend => {},
  setAmendableOrders: orders => {},
  cancelAmendmentFlow: () => {},
  showPoaPreventStoreSwitchPopup: callback => {},
  /* eslint-enable no-unused-vars */
})

export const POST_ORDER_AMEND_STATE_NAME = 'poa_order'
export const POST_ORDER_AMEND_BROADCAST_CHANNEL_NAME =
  'poa_order_broadcast_channel'

export const usePostOrderAmendment = (
  checkoutAddress,
  updateAddress,
  accountData
) => {
  // Feature flag value
  // eslint-disable-next-line no-unused-vars
  const { isEnabled: isAmendEnabled } = getConfigFor(
    SPLIT_FEATURES.POA_FIRST_MILESTONE
  )
  const { isEnabled: isDeletedAddressHandlingEnabled } = getConfigFor(
    SPLIT_FEATURES.FE_POA_DELETED_ADDRESS_HANDLING
  )

  // order data to amend
  const [_orderToAmend, _setOrderToAmend] = useSyncableSessionStorage({
    name: POST_ORDER_AMEND_STATE_NAME,
    channelName: POST_ORDER_AMEND_BROADCAST_CHANNEL_NAME,
  })
  // amendable order
  const [amendableOrders, _setAmendableOrders] = useState([])
  const [isAmendFromCart, setIsAmendFromCart] = useState(false)
  // unaplicable modal
  const [unaplicableItems, _setUnaplicableItems] = useState([])
  const [showUnapplicableItemsPopup, setShowUnapplicableItemsPopup] =
    useState(false)

  const clearOrderToAmend = useCallback(() => {
    _setOrderToAmend(null)
  }, [_setOrderToAmend])

  const setUnaplicableItems = useCallback(items => {
    if (items.length > 0) {
      _setUnaplicableItems(items)
      setShowUnapplicableItemsPopup(true)
    }
  }, [])

  const closeUnaplicablePopup = useCallback(() => {
    setShowUnapplicableItemsPopup(false)
    _setUnaplicableItems([])
  }, [])

  const setAmendableOrders = useCallback(
    orders => {
      // if cannot find order to amend, cancel flow
      if (
        _orderToAmend &&
        !(orders || []).some(
          order => order.referenceNumber === _orderToAmend.referenceNumber
        )
      ) {
        // cancel amendment flow
        clearOrderToAmend()
      }
      _setAmendableOrders(orders)
    },
    [_orderToAmend, clearOrderToAmend]
  )

  const isAmendedOrderAddressDeleted = useCallback(
    orderToAmend => {
      if (!isDeletedAddressHandlingEnabled) {
        return false
      }

      const addresses = accountData?.addresses || []
      const matchedAddress = addresses.find(
        accountAddress =>
          String(accountAddress.id) ===
          String((orderToAmend || _orderToAmend)?.address?.id)
      )

      return !matchedAddress
    },
    [_orderToAmend, accountData?.addresses, isDeletedAddressHandlingEnabled]
  )

  const setOrderToAmend = useCallback(
    async (orderToAmend, opt) => {
      const { isAmendFromCart: isAmendFromCartOpt } = opt || {
        isAmendFromCart: false,
      }
      if (!orderToAmend || orderToAmend.id === '0') {
        clearOrderToAmend()
        return
      }

      // od data use amendment field, need to update to conform with checkout data
      if (orderToAmend.amendment) {
        try {
          orderToAmend.cutoffTime = orderToAmend.amendment?.latestTimeToAmend
        } catch (error) {
          // silent error
        }
      }

      const orderType = getUserType() === 'B2B' ? 'B2B' : 'DELIVERY'

      // we need to update the address id to match order address id
      const { address } = orderToAmend
      const deliveryAreaSupported = await searchStoreByLocation(
        {
          pincode: address.pincode,
          latitude: address.latitude,
          longitude: address.longitude,
        },
        { orderType }
      )

      let mainAddress = address.address
      if (mainAddress.search(address.pincode) === -1) {
        mainAddress = `${mainAddress}, ${address.city} ${address.pincode}`
      }

      const isAddressDeleted = isAmendedOrderAddressDeleted(orderToAmend)

      await updateAddress(
        {
          pincode: address.pincode && String(address.pincode),
          address: mainAddress,
          location: {
            latitude: address.latitude,
            longitude: address.longitude,
          },
          latitude: address.latitude,
          longitude: address.longitude,
          type: orderType,
          availableStores: deliveryAreaSupported.availableStores,
          storeType: deliveryAreaSupported.storeType,
          storeId: deliveryAreaSupported.storeId,
          deliveryFee: deliveryAreaSupported.deliveryFee,
          storeName: deliveryAreaSupported.storeName,
          id: !isAddressDeleted ? Number(address.id) : null,
          building: address.metaData?.BuildingName || address.buildingName,
          clientId: deliveryAreaSupported.clientId,
        },
        true,
        true
      )

      // set to local state
      _setOrderToAmend(orderToAmend, {
        persist: !isAmendFromCartOpt,
      })
      setIsAmendFromCart(isAmendFromCartOpt)

      // force pcm changes on diff store id
      if (
        checkoutAddress &&
        Number(checkoutAddress.pincode) !== Number(orderToAmend.address.pincode)
      ) {
        // notify user of pcm changes
      }
    },
    [
      isAmendedOrderAddressDeleted,
      updateAddress,
      _setOrderToAmend,
      checkoutAddress,
      clearOrderToAmend,
    ]
  )

  // reset state, back to normal flow
  const cancelAmendmentFlow = useCallback(() => {
    clearOrderToAmend()
    setIsAmendFromCart(false)
    setAmendableOrders([])
  }, [clearOrderToAmend, setAmendableOrders])

  useEffect(() => {
    // some pcm changes to another address
    if (
      _orderToAmend &&
      Number(checkoutAddress.id) !== Number(_orderToAmend.address?.id) &&
      !(!checkoutAddress.id && isAmendedOrderAddressDeleted())
    ) {
      // do something when address changed
      // we may want to prompt user this action will bail user from poa flow
      cancelAmendmentFlow()
    }
  }, [
    cancelAmendmentFlow,
    checkoutAddress,
    _orderToAmend,
    isAmendedOrderAddressDeleted,
  ])

  // prevent store switch
  const [isPoaPreventStoreSwitchPopupShown, setShowPoaPreventStoreSwitchPopup] =
    useState(false)

  const poaPreventStoreSwitchPopupCallback = useRef()

  const showPoaPreventStoreSwitchPopup = useCallback(callback => {
    setShowPoaPreventStoreSwitchPopup(true)
    poaPreventStoreSwitchPopupCallback.current = callback
  }, [])

  const handlePoaPreventStoreSwitchConfirmation = useCallback(() => {
    poaPreventStoreSwitchPopupCallback.current?.()
    setShowPoaPreventStoreSwitchPopup(false)
  }, [])

  return {
    isAmendEnabled,
    orderToAmend: _orderToAmend,
    isAmendFromCart,
    unaplicableItems,
    setUnaplicableItems,
    amendableOrders,
    setAmendableOrders,
    setOrderToAmend,
    showUnapplicableItemsPopup,
    setShowUnapplicableItemsPopup,
    closeUnaplicablePopup,
    cancelAmendmentFlow,
    isPoaPreventStoreSwitchPopupShown,
    showPoaPreventStoreSwitchPopup,
    handlePoaPreventStoreSwitchConfirmation,
  }
}

const PostOrderAmendProvider = ({
  checkoutAddress,
  updateAddress,
  accountData,
  children,
}) => {
  const router = useRouter()
  const {
    isAmendEnabled,
    orderToAmend,
    isAmendFromCart,
    unaplicableItems,
    setUnaplicableItems,
    amendableOrders,
    setAmendableOrders,
    setOrderToAmend,
    showUnapplicableItemsPopup,
    closeUnaplicablePopup,
    cancelAmendmentFlow,
    isPoaPreventStoreSwitchPopupShown,
    showPoaPreventStoreSwitchPopup,
    handlePoaPreventStoreSwitchConfirmation,
  } = usePostOrderAmendment(checkoutAddress, updateAddress, accountData)

  useEffect(() => {
    // back handler
    function handleBack(url) {
      if (url === '/cart') {
        // cancel amend flow
        cancelAmendmentFlow()
      }
    }
    // add back handler
    if (isAmendFromCart && router.pathname === '/checkout') {
      router.events?.on('beforeHistoryChange', handleBack)
    }
    // remove back handler
    return () => {
      router.events?.off('beforeHistoryChange', handleBack)
    }
  }, [cancelAmendmentFlow, isAmendFromCart, router])

  return (
    <PostOrderAmendContext.Provider
      value={{
        isAmendEnabled,
        orderToAmend,
        isAmendFromCart,
        unaplicableItems,
        setUnaplicableItems,
        amendableOrders,
        setAmendableOrders,
        setOrderToAmend,
        cancelAmendmentFlow,
        showPoaPreventStoreSwitchPopup,
      }}
    >
      {children}

      {/* items unavailable for POA popup */}
      <NdsThemeProvider>
        <Modal open={unaplicableItems.length > 0 && showUnapplicableItemsPopup}>
          <ModalTitle>
            <Typography variant="h5">Selected items unavailable</Typography>
          </ModalTitle>
          <ModalContent>
            <Typography variant="body2">
              {"Items can't be added to upcoming delivery"}
            </Typography>
            {unaplicableItems.map(item => (
              <UnapplicableProduct key={item.id} item={item} />
            ))}
          </ModalContent>
          <ModalActions>
            {router.asPath !== '/cart' && (
              <ButtonOutlined
                onClick={() => {
                  router.replace('/cart')
                  closeUnaplicablePopup()
                }}
              >
                Back to cart
              </ButtonOutlined>
            )}
            <ButtonContained
              onClick={() => {
                closeUnaplicablePopup()
              }}
            >
              Continue
            </ButtonContained>
          </ModalActions>
        </Modal>

        {isPoaPreventStoreSwitchPopupShown && (
          <ProductQuantityAdjustmentMessage
            message={
              'We have revised your order to the maximum quantity allowed.'
            }
            onConfirm={handlePoaPreventStoreSwitchConfirmation}
          />
        )}
      </NdsThemeProvider>
    </PostOrderAmendContext.Provider>
  )
}

const PostOrderAmendProviderWrapper = ({ children }) => {
  return (
    <AccountConsumer>
      {({ accountData }) => (
        <CheckoutAddressConsumer>
          {({ checkoutAddress, update: updateAddress }) => (
            <PostOrderAmendProvider
              accountData={accountData}
              checkoutAddress={checkoutAddress}
              updateAddress={updateAddress}
            >
              {children}
            </PostOrderAmendProvider>
          )}
        </CheckoutAddressConsumer>
      )}
    </AccountConsumer>
  )
}

export default PostOrderAmendProviderWrapper

export const PostOrderAmendConsumer = PostOrderAmendContext.Consumer
