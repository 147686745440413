/**
 * Capitalize first letter of string and lowercase remaining characetrs
 * @param {string} inputString
 * @return {string} Formatted string
 */

export const capitalizeFirstLetterLowercaseOther = str => {
  str = (str && str.trim()) || ''
  return str && str[0].toUpperCase() + str.substring(1).toLowerCase()
}

/**
 * Capitalize only first letter of string, while keep others unchanged
 * @param {string} inputString
 * @return {string} Formatted string
 */

export const capitalizeOnlyFirstLetter = str => {
  str = str = (str && str.trim()) || ''
  return str && str[0].toUpperCase() + str.substring(1)
}

/**
 * Removes all the leading zeros from input string or number
 * @param {string|Number} input
 * @return {string} String without zero at start
 */

export const trimZeroFromStart = str => {
  if (typeof str === 'string' || typeof str === 'number') {
    str = str + '' // to change number to string in case number is string
    const replaced = str.replace(/^0+/g, '')
    return replaced
  }
  return str
}

export const decodeEntities = input => {
  //using client-side only
  if (typeof window === 'undefined' || typeof input !== 'string') {
    return input
  }

  const element = document.createElement('textarea')
  element.innerHTML = input
  const data =
    element.childNodes.length === 0 ? '' : element.childNodes[0].nodeValue

  return data
}

export const truncateText = element => {
  var wordArray = element && element.innerHTML.split('')
  while (wordArray?.length && element.scrollHeight > element.offsetHeight) {
    wordArray.pop()
    element.innerHTML = wordArray.join('') + '...'
  }
}
