import React, { Component } from 'react'
import isEmpty from 'lodash/isEmpty'
import getConfig from 'next/config'
import {
  get,
  patch,
  headers,
  __INTERNAL_ERROR_HANDLER_DO_NOT_USE__,
} from './../../lib/fetch'
import nookies from 'nookies'
import jwtDecode from 'jwt-decode'
import { Router } from './../../routes'
import {
  getDomainForCookies,
  authTokenCookiOptions,
} from '../../utils/cookieUtils'
import { AccountContext } from './AccountContext'
import { REFRESH_TOKEN_BEFORE_AGE, AUTH_TOKEN_KEY } from '../../utils/constants'
import { getCurrentHost } from '../../utils/Utils'
import getBusinessProfileState, {
  provisionB2bUser,
} from '../../utils/getBusinessProfileState'
import SprigService from '../../utils/SprigService'

const {
  publicRuntimeConfig: { AUTH0_IDENTITY_API },
} = getConfig()

const { Provider } = AccountContext

export const TOKEN_REFRESH_INTERVAL = (REFRESH_TOKEN_BEFORE_AGE / 2) * 1000 // at half of token refresh limit in millisecods
const INVALID_ACCESS_TOKEN_ERROR_CODE = 401

class AccountProviderAuth0 extends Component {
  constructor(props) {
    super(props)
    const { defaultAccountData: accountData } = this.props

    this.state = {
      accountData,
      // Persist checkout data from checkout form.
      // This must not survive page refresh.
      // To be honest I am not sure where to put this
      // so putting it here first.
      checkoutData: {},
    }

    this.update = this.update.bind(this)
    this.login = this.login.bind(this)
    this.logout = this.logout.bind(this)
    this.updateCheckoutData = this.updateCheckoutData.bind(this)
    this.cleanUpSideEffects = this.destroySideEffects.bind(this)
    this.refreshAccountData = this.refreshAccountData.bind(this)
    this.subscribeToHttpErrors = this.subscribeToHttpErrors.bind(this)
    this.updatePlusAlert = this.updatePlusAlert.bind(this)

    if (accountData) {
      this.setupSideEffects(accountData)
    }
  }

  passAccountDataToGlobal(accountData) {
    // It is probably a very bad idea to modify `__NEXT_DATA__` but I am not
    // sure if there is a better way.
    // What I want to do is let server fetch account data, and then pass
    // it to the client via props. This would work well if the user is authenticated
    // and the server provides the account data. However, if the user is not authenticated
    // on page load and then logs in subsequently, there is no way to modify `props.account`
    // and I need to modify that because `verifyPagePermission` depends on this to know
    // whether a user is authenticated or not.
    // I am also not sure if there is a way for `req.cookies` to be removed mid-way through
    // a request so the middlewares that come after it will not see `auth_token` cookie
    // if a user's token is `401`. So this is a work-around that isn't so bad.
    if (
      typeof window !== 'undefined' &&
      window.__NEXT_DATA__ &&
      window.__NEXT_DATA__.props
    ) {
      window.__NEXT_DATA__.props.account = accountData
    }
  }

  // I don't know of any way for non-React components to communicate
  // with external functions so I use pub/sub to achieve similar things.
  subscribeToHttpErrors() {
    __INTERNAL_ERROR_HANDLER_DO_NOT_USE__.subscribe(
      (
        code,
        onAuthErrorLogout = () => {
          this.login()
        }
      ) => {
        if (code === INVALID_ACCESS_TOKEN_ERROR_CODE) {
          /**
           * TODO:
           * - Enable(remove following comment) this logout once we have all APIs covered under token validations.
           */
          this.logout(onAuthErrorLogout)
        }
      }
    )
  }

  initTokenRefreshTick() {
    this.clearTokenRefreshTick()

    this.tokenRefreshTick = setInterval(function () {
      const { [AUTH_TOKEN_KEY]: authToken } = nookies.get()

      if (authToken) {
        const decodedToken = jwtDecode(authToken)
        const { exp } = decodedToken
        const isTokenAboutToExpire =
          exp && exp - new Date().getTime() / 1000 < REFRESH_TOKEN_BEFORE_AGE
        if (isTokenAboutToExpire) {
          fetch('/refresh-tkn', {
            headers: {
              'Content-Type': 'application/json',
            },
          }).catch(e => e)
        }
      }
    }, TOKEN_REFRESH_INTERVAL)
  }

  provisionPartialB2bUser(accountProps) {
    const { shouldProvisionPartialB2bUser } = getBusinessProfileState({
      accountData: accountProps,
    })

    if (shouldProvisionPartialB2bUser) {
      provisionB2bUser()
    }
  }

  setupSideEffects(accountData) {
    //sets global account data
    this.passAccountDataToGlobal(accountData)

    // Subscribe early so if consumer components make a fetch causing a `401`
    // we are ready.
    // Only subscribe when logged in to prevent `401` during
    // login failures from redirecting to login page (i.e. from checkout page)
    this.subscribeToHttpErrors()

    //sets token refresh
    this.initTokenRefreshTick()
  }

  clearGlobalAccountData() {
    if (
      typeof window !== 'undefined' &&
      window.__NEXT_DATA__ &&
      window.__NEXT_DATA__.props
    ) {
      delete window.__NEXT_DATA__.props.account
    }
  }

  unsubscribeFromHttpErrors() {
    __INTERNAL_ERROR_HANDLER_DO_NOT_USE__.empty()
  }

  clearTokenRefreshTick() {
    clearInterval(this.tokenRefreshTick)
  }

  destroySideEffects() {
    //clear global account data
    this.clearGlobalAccountData()

    // Unsubscribe early in case setState triggers consumer components
    // to do fetches that result in `401` which will cause an event
    // to be published unneccessarily.
    this.unsubscribeFromHttpErrors()

    //clear token refresh
    this.clearTokenRefreshTick()

    //clear auth cookie
    const domain = getDomainForCookies()
    nookies.destroy({}, AUTH_TOKEN_KEY, authTokenCookiOptions(domain))
  }

  login() {
    const loginUrl = `/login?redirect=${encodeURIComponent(
      Router.router.asPath
    )}`
    window.location.href = loginUrl
  }

  // `callback` is added here so it is easier to make this method
  // async like `login` if we ever need to invalidate the access token
  // by posting to the server.
  logout(callback) {
    // It should be safe to expect that by default after logging out we want to redirect
    // user to the login screen. However, if you decide not to do it, defining any callback
    // function will override this behavior.
    // -
    // If you want to add additional message to login screen to inform user that s/he has been
    // logged out, feel free to use additional `?logged_out` to add flash message to `pages/login`.
    const onUpdate =
      typeof callback === 'function'
        ? callback
        : () => (window.location.href = `/logout`)

    this.destroySideEffects()

    // Clear out checkout data once you log out since it is user-specific.
    this.updateCheckoutData(undefined)

    this.setState({ accountData: undefined })

    this.update(undefined, onUpdate)
  }

  account() {
    Router.pushRoute(`/accounts`)
  }

  async refreshAccountData(callback) {
    if (isEmpty(this.state.accountData)) {
      return
    }
    const mergedProfile = await get(`${getCurrentHost()}/user-profile`, {
      headers: headers(),
    })

    this.setState({ accountData: mergedProfile }, () => {
      this.passAccountDataToGlobal(mergedProfile)
      callback && callback()
    })
  }

  patchAccountData = async (patchedData, callback) => {
    await patch(`${AUTH0_IDENTITY_API}/sdk/user/profile`, {
      headers: headers(),
      body: JSON.stringify(patchedData),
    })
    /* istanbul ignore next */
    this.refreshAccountData(callback)
  }

  update(accountData, cb) {
    this.setState({ accountData }, cb)
  }

  updateCheckoutData(checkoutData) {
    this.setState({ checkoutData: checkoutData ? checkoutData : {} })
  }
  updatePlusAlert(hasPlusAlert) {
    this.setState({ hasPlusAlert })
  }

  componentDidMount() {
    const { accountData } = this.state
    if (accountData?.id) {
      SprigService.pushAccountData(accountData)
    }
  }

  componentWillUnmount() {
    this.unsubscribeFromHttpErrors()
    this.clearTokenRefreshTick()
  }

  render() {
    const { children } = this.props
    const { accountData, hasPlusAlert } = this.state
    const { [AUTH_TOKEN_KEY]: authToken } = nookies.get() || {}
    const isLoggedIn = !isEmpty(accountData) && Boolean(authToken)

    return (
      <Provider
        value={{
          accountData: accountData,
          update: this.update,
          patchAccountData: this.patchAccountData,
          login: this.login,
          account: this.account,
          logout: this.logout,
          isLoggedIn,
          refreshAccountData: this.refreshAccountData,
          hasPlusAlert,
          updatePlusAlert: this.updatePlusAlert,
          provisionPartialB2bUser: this.provisionPartialB2bUser,
          // This probably doesn't belong here but I am putting it here first
          // as a quick fix.
          checkoutData: this.state.checkoutData,
          updateCheckoutData: this.updateCheckoutData,
        }}
      >
        {children}
      </Provider>
    )
  }
}

export default AccountProviderAuth0
