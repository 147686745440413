import styled from 'styled-components'

export const StyledPopup = styled.div`
  background-color: ${props => props.bgColor || 'rgba(51, 51, 51, 0.8)'};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10050;
  overflow: ${({ isFullScreen }) => (isFullScreen ? 'hidden' : 'auto')};
  outline: none;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;

  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-bottom: ${props => (props.noMarginToAlign ? '0' : '-10rem')};
  }
`

export const StyledPopupBody = styled.div`
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  margin: auto;
`
