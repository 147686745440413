export const PAYMENT_STATUS = {
  PENDING: 'PENDING',
  PAID: 'PAID',
  REFUND: 'REFUND',
}

export const ORDER_STATUSES = {
  Pending: 'PENDING',
  Picking: 'PICKING',
  Picked: 'PICKED',
  Packing: 'PACKING',
  Packed: 'PACKED',
  Checking: 'CHECKING',
  Checked: 'CHECKED',
  Delayed: 'DELAYED',
  Dispatched: 'DISPATCHED',
  Completed: 'COMPLETED',
  Cancelled: 'CANCELLED',
  Returned: 'RETURNED',
  Undelivered: 'UNDELIVERED',
  InTransit: 'IN_TRANSIT',
  Processing: 'PROCESSING',
}

export const QUICKBUY_STATUSES = {
  Pending: 'PENDING',
  Approved: 'APPROVED',
  Expired: 'EXPIRED',
  Rejected: 'REJECTED',
  Cancelled: 'CANCELLED',
}

export const STORE_TYPES = {
  PFC: 'PFC',
  FFS: 'FFS',
}

export const ORDER_TYPES = {
  HomeDelivery: 'DELIVERY',
  ClickAndCollect: 'PICKUP',
  B2B: 'B2B',
}

const TRY_AGAIN = 'Please try again later.'

const loadPFCSteps = (type, orderStatus) => {
  return [
    { status: [ORDER_STATUSES.Pending], name: 'Order placed' },
    { status: [ORDER_STATUSES.Packed], name: 'Picked' },
    {
      status: [ORDER_STATUSES.Dispatched, ORDER_STATUSES.InTransit],
      name:
        type === ORDER_TYPES.HomeDelivery || type === ORDER_TYPES.B2B
          ? 'On the way'
          : 'Ready for collection',
    },
    {
      status: [ORDER_STATUSES.Completed, ORDER_STATUSES.Undelivered],
      name:
        orderStatus !== ORDER_STATUSES.Undelivered
          ? type === ORDER_TYPES.HomeDelivery || type === ORDER_TYPES.B2B
            ? 'Delivered / Collected'
            : 'Collected'
          : type === ORDER_TYPES.HomeDelivery || type === ORDER_TYPES.B2B
          ? 'Delivery unsuccessful'
          : 'Unable to collect',
    },
  ]
}

const loadFFSSteps = (type, orderStatus) => {
  return [
    { status: [ORDER_STATUSES.Pending], name: 'Order placed' },
    {
      status: [
        ORDER_STATUSES.Picking,
        ORDER_STATUSES.Picked,
        ORDER_STATUSES.Checking,
        ORDER_STATUSES.Checked,
        ORDER_STATUSES.Packing,
        ORDER_STATUSES.Processing,
      ],
      name: 'Picked',
    },
    {
      status: [ORDER_STATUSES.Packed, ORDER_STATUSES.Dispatched],
      name:
        type === ORDER_TYPES.HomeDelivery || type === ORDER_TYPES.B2B
          ? 'On the way'
          : 'Ready for collection',
    },
    {
      status: [ORDER_STATUSES.Completed, ORDER_STATUSES.Undelivered],
      name:
        orderStatus !== ORDER_STATUSES.Undelivered
          ? type === ORDER_TYPES.HomeDelivery || type === ORDER_TYPES.B2B
            ? 'Delivered / Collected'
            : 'Collected'
          : type === ORDER_TYPES.HomeDelivery || type === ORDER_TYPES.B2B
          ? 'Delivery unsuccessful'
          : 'Unable to collect',
    },
  ]
}

export const loadSteps = (storeType, type, status) => {
  //PFC and FFS are different at step 2 and step 3, duplicate step 1 and step 4
  //readable code purpose has caused the duplication
  if (storeType === STORE_TYPES.PFC) {
    return loadPFCSteps(type, status)
  }
  return loadFFSSteps(type, status)
}

export const OrderErrorTypes = {
  SERVICE_UNAVAILABLE: 'SERVICE_UNAVAILABLE',
  SLOT_CAPACITY: 'SLOT_CAPACITY',
  STOCK_UNAVAILABLE: 'STOCK_UNAVAILABLE',
  OFFER_REDEMPTION: 'OFFER_REDEMPTION',
  INVALID_PAYMENT: 'INVALID_PAYMENT',
  AUTH_EXCEPTION: 'AUTH_EXCEPTION',
  PAYMENT_FAILED: 'PAYMENT_FAILED',
  DEFAULT: 'DEFAULT',
}

export const QuickbuyOrderErrors = (errorMessage, quickbuyErrorCode) => ({
  header: errorMessage,
  body: TRY_AGAIN,
  cta: 'Okay, got it',
  type: quickbuyErrorCode,
})

export const OrderErrors = {
  SERVICE_UNAVAILABLE: {
    header: 'We are currently experiencing some technical issues',
    body: TRY_AGAIN,
    cta: 'Okay, got it',
    type: OrderErrorTypes.SERVICE_UNAVAILABLE,
  },
  SLOT_CAPACITY: {
    header: 'The time slot you selected is no longer available',
    body: 'Please return to the checkout page to select a new time slot.',
    cta: 'Select new time slot',
    type: OrderErrorTypes.SLOT_CAPACITY,
  },
  STOCK_UNAVAILABLE: {
    header: 'The product is no longer available',
    body: 'It will be removed from your cart.',
    cta: 'Okay, got it',
    type: OrderErrorTypes.STOCK_UNAVAILABLE,
  },
  OFFER_REDEMPTION: {
    header: 'This offer has been fully redeemed',
    body: "We're unable to apply it to your cart.",
    cta: 'Okay, got it',
    type: OrderErrorTypes.OFFER_REDEMPTION,
  },
  INVALID_PAYMENT: {
    header: 'This payment method is not supported',
    body: 'Please try again with one of the following payment methods: Mastercard, Visa, American Express',
    cta: 'Okay, got it',
    type: OrderErrorTypes.INVALID_PAYMENT,
  },
  AUTH_EXCEPTION: {
    header: 'Your payment was unsuccessful',
    body: 'Please review your payment details and try again.',
    cta: 'Okay, got it',
    type: OrderErrorTypes.AUTH_EXCEPTION,
  },
  PAYMENT_FAILED: {
    header: 'Your payment was unsuccessful',
    body: 'Your card was not charged. Please try again',
    cta: 'Cancel',
    type: OrderErrorTypes.PAYMENT_FAILED,
  },
  DEFAULT: {
    header: 'We are currently experiencing some technical issues',
    body: TRY_AGAIN,
    cta: 'Okay, got it',
    type: OrderErrorTypes.DEFAULT,
  },
}

export const getStoreTypeFromOrganizationData = (organizationData, storeId) => {
  return organizationData &&
    organizationData.config.bulkOrderSupport.targetStoreId === storeId
    ? STORE_TYPES.PFC
    : STORE_TYPES.FFS
}

const getSlotStartTime = (currentDate, timestamp = '0:0') => {
  const newDate = new Date(currentDate)
  newDate.setHours(timestamp.split(':')[0])
  return newDate
}

export const checkInTransitState = (
  orderStatus,
  storeType,
  orderType,
  preferredDate,
  slotStart
) => {
  return orderStatus === ORDER_STATUSES.Packed &&
    storeType === STORE_TYPES.PFC &&
    orderType === ORDER_TYPES.ClickAndCollect &&
    new Date() > getSlotStartTime(preferredDate, slotStart)
    ? ORDER_STATUSES.InTransit
    : orderStatus
}

export const getOrderErrorMsg = errorMsg => {
  if (!errorMsg) {
    return OrderErrors.DEFAULT
  } else if (errorMsg.search(/service is experiencing some issues/i) > -1) {
    return OrderErrors.SERVICE_UNAVAILABLE
  } else if (
    errorMsg.search(/Failed to reserve the slot capacity/i) > -1 ||
    errorMsg.search(/Slot not available/i) > -1 ||
    errorMsg.search(/Failed to cancel the reserved slot capacity/i) > -1 ||
    errorMsg.search(/Invalid PreferredDate\/PreferredSlotId/i) > -1
  ) {
    return OrderErrors.SLOT_CAPACITY
  } else if (errorMsg.search(/Stock not available for product/i) > -1) {
    return OrderErrors.STOCK_UNAVAILABLE
  } else if (errorMsg.search(/Unable to add offer redemption/i) > -1) {
    return OrderErrors.OFFER_REDEMPTION
  } else if (errorMsg.search(/Invalid payment mode/i) > -1) {
    return OrderErrors.INVALID_PAYMENT
  } else if (errorMsg.search(/Customer Not Authorized/i) > -1) {
    return OrderErrors.AUTH_EXCEPTION
  } else if (errorMsg.search(/failed to authorize payment/i) > -1) {
    return OrderErrors.PAYMENT_FAILED
  } else {
    return OrderErrors.DEFAULT
  }
}

export const getQuickbuyOrderErrorMsg = errorMsg => {
  const regex = /\(Error code: (EC20[1-9]|EC21[0-9]|EC22[0-8])\)/i

  if (regex.test(errorMsg)) {
    const match = errorMsg.match(regex)

    return match && match.length > 1 && QuickbuyOrderErrors(errorMsg, match[1])
      ? QuickbuyOrderErrors(errorMsg, match[1])
      : OrderErrors.PAYMENT_FAILED
  }

  return {
    header: null,
    body: null,
    cta: null,
    type: null,
  }
}

export const FRESH_UNIT = 'kg'
