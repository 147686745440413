const splitCollectionThreshold = {
  SPLIT_PRODUCT_IMPRESSION: 100,
  SPLIT_HOMEPAGE_PRODUCT_IMPRESSION: 500,
  SPLIT_BANNER_IMPRESSION: 40,
  SPLIT_OTHER_BANNER_IMPRESSION: 100,
  SPLIT_RECIPE_IMPRESSION: 100,
}

const splitImpressionTypes = {
  SPLIT_PRODUCT_IMPRESSION: 'SPLIT_PRODUCT_IMPRESSION',
  SPLIT_HOMEPAGE_PRODUCT_IMPRESSION: 'SPLIT_HOMEPAGE_PRODUCT_IMPRESSION',
  SPLIT_BANNER_IMPRESSION: 'SPLIT_BANNER_IMPRESSION',
  SPLIT_OTHER_BANNER_IMPRESSION: 'SPLIT_OTHER_BANNER_IMPRESSION',
  SPLIT_RECIPE_IMPRESSION: 'SPLIT_RECIPE_IMPRESSION',
}

const splitThresholdList = {
  SPLIT_PRODUCT_IMPRESSION: 20,
  SPLIT_HOMEPAGE_PRODUCT_IMPRESSION: 20,
  SPLIT_BANNER_IMPRESSION: 5,
  SPLIT_OTHER_BANNER_IMPRESSION: 5,
  SPLIT_RECIPE_IMPRESSION: 5,
}

const SPLIT_EXCLUDE_LIST = [
  'productImpression',
  'productClick',
  'promoImpression',
  'promotionClick',
]

export {
  splitCollectionThreshold,
  splitImpressionTypes,
  splitThresholdList,
  SPLIT_EXCLUDE_LIST,
}
