const extractUrl = () => {
  const windowLocation = window.location
  let result = ''

  try {
    result = decodeURIComponent(
      windowLocation.href.replace(windowLocation.origin, '')
    )
  } catch (ex) {
    result = ''
  }

  return result
}

export default extractUrl
