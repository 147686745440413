import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import Toast from '@ntuctech/devex-tangram/Toast'
import SvgAlert from '@ntuctech/devex-tangram/Icon/SvgAlert'
import NdsThemeProvider from '@ntuctech/devex-tangram/Theme/NdsThemeProvider'

import { getConfigFor } from '../../utils/configService'
import { SPLIT_FEATURES } from '../../utils/constants'
import {
  createCartSignature,
  isAnyCartItemsInCategories,
} from './AgeConsentUtils'
import { useWebStorage } from '../PostOrdeAmendProvider/SyncableSessionStorage'
import SessionStorageService from '../../utils/SessionStorageService'
import { useRouter } from 'next/router'
import { trackAlcoholErrorNotificationViewed } from '../../utils/tracking/cartTracking'
import { EventTrackingContext } from '../EventTrackingProvider/EventTrackingProvider'

const AgeConsentContextDefault = {
  isAgeConsentSigned: false,
  setAgeConsentSigned: () => {},
  showConsentError: () => {},
  isCartItemsNeedAgeConsent: () => {},
}

const AgeConsentContext = createContext(AgeConsentContextDefault)

const useToastController = ({ onShow }) => {
  const [visible, setVisibility] = useState(false)
  const timeoutRef = useRef()

  const show = useCallback(() => {
    if (!visible) {
      onShow()
      setVisibility(true)
      clearTimeout(timeoutRef.current)
      timeoutRef.current = setTimeout(() => {
        setVisibility(false)
      }, 4000)
    }
  }, [onShow, visible])

  const dismiss = useCallback(() => {
    setVisibility(false)
  }, [])

  return [visible, show, dismiss]
}

const AGE_CONSENT_STATE_NAME = 'age_consent'
const AGE_CONSENT_ERROR_DEFERRED_FLAG_NAME = 'age_consent_error_deferred_flag'

export const AgeConsentProvider = ({ children }) => {
  const { track } = useContext(EventTrackingContext)
  const router = useRouter()

  const {
    isEnabled: isAgeConsentEnabled,
    configuration: ageConsentConfiguration,
  } = getConfigFor(SPLIT_FEATURES.CART_ALCOHOL_DISCLAIMER)

  const { configuration: ageConsentCategoryIds } = getConfigFor(
    SPLIT_FEATURES.CART_ALCOHOL_DISCLAIMER_CATEGORY_IDS
  )

  const [consentErrorVisible, _showConsentError, dismissConsentError] =
    useToastController(
      useMemo(
        () => ({
          onShow: () => {
            trackAlcoholErrorNotificationViewed({ track })
          },
        }),
        [track]
      )
    )

  const showConsentError = isDeferredTillNextPageLoad => {
    if (!isDeferredTillNextPageLoad) {
      _showConsentError()
    } else {
      SessionStorageService.setItem(AGE_CONSENT_ERROR_DEFERRED_FLAG_NAME, true)
    }
  }

  useEffect(() => {
    function handleRouteChangeComplete(url) {
      if (
        url.includes('cart') &&
        SessionStorageService.getItem(AGE_CONSENT_ERROR_DEFERRED_FLAG_NAME)
      ) {
        SessionStorageService.removeItem(AGE_CONSENT_ERROR_DEFERRED_FLAG_NAME)
        _showConsentError()
      }
    }

    router.events?.on('routeChangeComplete', handleRouteChangeComplete)
    return () => {
      router.events?.off('routeChangeComplete', handleRouteChangeComplete)
    }
  }, [_showConsentError, router])

  const [ageConsentState, setAgeConsentState] = useWebStorage(
    AGE_CONSENT_STATE_NAME,
    {
      signed: false,
      signature: null,
    },
    SessionStorageService
  )

  // should be called on cart and checkout page everytime re-render
  // meanings the cartItems params will always be fresh
  const isCartItemsNeedAgeConsent = cartItems => {
    // compare cartItems to the old value and clear the state if changed

    // 1. create the signature for newly added cart items
    const newSignature = createCartSignature(cartItems)

    // 2. compare with prev signature
    if (newSignature !== ageConsentState.signature) {
      // change in cart detected
      // 3. update signature & set state to false
      setAgeConsentState({
        signed: false,
        signature: newSignature,
      })
    }

    return isAnyCartItemsInCategories(
      cartItems,
      ageConsentCategoryIds.categories
    )
  }

  const isAgeConsentSigned = ageConsentState.signed
  const setAgeConsentSigned = isSigned => {
    setAgeConsentState({
      signed: isSigned,
      signature: ageConsentState.signature,
    })
  }

  useEffect(() => {
    // back handler
    function handleBack(url) {
      const isGoingToCartOrCheckoutPage =
        url.includes('cart') || url.includes('checkout')
      if (!isGoingToCartOrCheckoutPage) {
        // reset the state
        setAgeConsentState({
          signed: false,
          signature: ageConsentState.signature,
        })
      }
    }
    // add back handler if
    router.events?.on('beforeHistoryChange', handleBack)
    // remove back handler
    return () => {
      router.events?.off('beforeHistoryChange', handleBack)
    }
  }, [ageConsentState.signature, router, setAgeConsentState])

  return (
    <NdsThemeProvider>
      {isAgeConsentEnabled && consentErrorVisible ? (
        <Toast
          type="alert"
          icon={<SvgAlert />}
          action="Dismiss"
          onAction={() => {
            dismissConsentError()
          }}
          message={ageConsentConfiguration.errorMessage}
          inline
        />
      ) : null}
      <AgeConsentContext.Provider
        value={{
          isAgeConsentSigned,
          setAgeConsentSigned,
          showConsentError,
          isCartItemsNeedAgeConsent,
        }}
      >
        {children}
      </AgeConsentContext.Provider>
    </NdsThemeProvider>
  )
}

export const AgeConsentConsumer = AgeConsentContext.Consumer

export const useAgeConsent = () => {
  return useContext(AgeConsentContext)
}
