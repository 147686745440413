module.exports = {
  EXPRESS_SESSION_MAX_AGE_IN_MS: 15 * 24 * 60 * 60 * 1000, // 15 days in mili seconds
  AUTH_COOKIE_MAX_AGE_IN_MS: 30 * 24 * 60 * 60 * 1000, // 30 days in mili seconds
  AUTH_COOKIE_MAX_AGE_IN_S: 30 * 24 * 60 * 60, // 30 days in seconds
  REFRESH_TOKEN_BEFORE_AGE: 10 * 60, // 10 minutes in seconds
  AUTH_TOKEN_KEY: 'auth_token',
  FAIRPRICE_CLAIM_KEY: 'fp_claim',
  AUTH_CONNECTION_ID: 'NE-Digital-ID-Platform-Connection',
  AUTH_SCOPE:
    'openid email profile offline_access scid:read:profile scid:update:profile scid:update:preferences scid:verify:myinfo scid:update:account_security scid:read:qr',
  PARAM_AUTH0_ENTRY_SOURCE: 'entry_source',
  PARAM_AUTH0_SIGNUP_REQUEST: 'signup_request',
  FORCE_REFRESH_TOKEN_URI: 'refresh-tkn?force=true',
  USER_TYPE_KEY: 'user_type',
}
