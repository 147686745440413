import React from 'react'

export default React.createContext({
  API_URL: 'https://fairprice.smartstore.express/api',
  asPath: '/',
  experiments: {},
  remoteConfig: {},
  organizationData: {},
  multiVariantExperiments: [],
})
