import React from 'react'
import isEqual from 'lodash/isEqual'
import getConfig from 'next/config'
import { post, get, del, put, headers } from '../../lib/fetch'
import { AccountConsumer } from '../AccountProvider'
import dynamic from 'next/dynamic'
const NotificationBar = dynamic(() => import('../NotificationBar'))
import { objectValues } from '../../lib/polyfills'
import { IdleQueue } from '../../lib/idlize/IdleQueue'
const queue = new IdleQueue()

const {
  publicRuntimeConfig: { API_URL },
} = getConfig()

export const Context = React.createContext({
  wishLists: {},
  includes: () => {},
  addNewWishList: () => {},
  renameWishList: () => {},
  deleteWishList: () => {},
  updateWishlists: () => {},
  getProductWishLists: () => {},
  updateProductForSingleWishList: () => {},
})

class WishlistProviderWrapper extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      // When it's a function it will be invoked only at
      // client-side so local storage can be used for hydrating.
      wishLists: props.wishLists || {},
      productListMap: {},
      wishListsLoaded: false,
    }
    this.mapProductsToLists = this.mapProductsToLists.bind(this)
    this.updateWishlists = this.updateWishlists.bind(this)
    this.getProductWishLists = this.getProductWishLists.bind(this)
    this.deleteWishList = this.deleteWishList.bind(this)
    this.renameWishList = this.renameWishList.bind(this)
    this.addNewWishList = this.addNewWishList.bind(this)
    this.includes = this.includes.bind(this)
    this.createWishList = this.createWishList.bind(this)
    this.updateProductForSingleWishList =
      this.updateProductForSingleWishList.bind(this)
    this.loadWishlist = this.loadWishlist.bind(this)
  }

  loadWishlist() {
    get('wish-list?includeTagDetails=true', {
      headers: headers(),
    })
      .then(res => {
        const wishListObject = {}
        res.data &&
          res.data.wishList &&
          res.data.wishList.map(item => {
            wishListObject[item.id] = item
          })
        this.setState({
          wishLists: wishListObject,
          productListMap: this.mapProductsToLists(objectValues(wishListObject)),
          wishListsLoaded: true,
        })
      })
      .catch(err => {
        this.setState({
          wishListsLoaded: true,
        })
        return err
      })
  }

  componentDidMount() {
    if (this.props.isLoggedIn) {
      queue.pushTask(() => {
        this.loadWishlist()
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isLoggedIn && this.props.isLoggedIn) {
      this.loadWishlist()
    }
    if (!this.props.isLoggedIn) {
      if (
        !isEqual(this.state.wishLists, {}) ||
        !isEqual(this.state.productListMap, {})
      ) {
        this.setState({
          wishLists: {},
          productListMap: {},
        })
      }
    }
  }

  getProductWishLists(productId) {
    const result = this.state.productListMap[productId] || []
    return result.filter(
      item => this.state.wishLists[item] && this.state.wishLists[item].id
    )
  }

  mapProductsToLists(wishLists) {
    let productListMap = {}
    wishLists.map(wishlist => {
      if (wishlist.productId) {
        wishlist.productId.map(product => {
          const { [product]: val, ...others } = productListMap

          if (val) {
            if (!val.includes(wishlist.id)) {
              productListMap = {
                ...others,
                [product]: val.concat(wishlist.id),
              }
            }
          } else {
            productListMap = {
              ...others,
              [product]: [wishlist.id],
            }
          }
        })
      }
    })
    return productListMap
  }

  deleteWishList(id) {
    return del(`${API_URL}/wish-list/${id}`, {
      headers: headers(),
    })
      .then(() => {
        // eslint-disable-next-line no-unused-vars
        const { [id]: val, ...restOthers } = this.state.wishLists
        const newWishListObject = { ...restOthers }
        this.setState({
          wishLists: restOthers,
          productListMap: this.mapProductsToLists(
            objectValues(newWishListObject)
          ),
        })
        return 'Resolved'
      })
      .catch(() => {
        return 'Rejected'
      })
  }

  renameWishList(wishlistName, id) {
    // eslint-disable-next-line no-unused-vars
    const { name, ...others } = this.state.wishLists[id]
    return put(`wish-list/${id}`, {
      headers: headers(),
      body: JSON.stringify({
        ...others,
        name: wishlistName,
      }),
    })
      .then(response => {
        const data = response.data.wishList
        const { [id]: val, ...others } = this.state.wishLists
        const newWishLists = { ...others, [id]: { ...val, ...data } }
        this.setState({
          wishLists: newWishLists,
          productListMap: this.mapProductsToLists(objectValues(newWishLists)),
        })
        return 'Resolved'
      })
      .catch(() => {
        return 'Rejected'
      })
  }

  createWishList(name, productId) {
    return post('wish-list', {
      body: JSON.stringify({
        name: name,
        productId: productId ? [productId] : [],
      }),
      headers: headers(),
    })
      .then(response => {
        const wishlistData = response.data && response.data.wishList
        // eslint-disable-next-line no-unused-vars
        const { [wishlistData.id]: val, ...others } = this.state.wishLists
        const newWishLists = {
          ...others,
          [wishlistData.id]: {
            ...wishlistData,
            productId: productId ? [productId] : [],
          },
        }
        this.setState({
          wishLists: newWishLists,
          productListMap: this.mapProductsToLists(objectValues(newWishLists)),
        })

        return {
          newWishListId: wishlistData.id,
          newWishLists,
        }
      })
      .catch(() => {
        return null
      })
  }

  updateProductForSingleWishList(id, wishListId) {
    return put(`product/${id}/wish-list`, {
      headers: headers(),
      body: JSON.stringify({
        wishListId:
          this.state.productListMap[id] &&
          this.state.productListMap[id].includes(wishListId)
            ? this.state.productListMap[id].filter(
                eachwishId => eachwishId !== wishListId
              )
            : this.state.productListMap[id]
            ? this.state.productListMap[id].concat(wishListId)
            : [wishListId],
      }),
    })
      .then(() => {
        let newWishListObject = {}
        let wishlist = {
          ...this.state.wishLists[wishListId],
          productId: this.state.wishLists[wishListId]['productId'].includes(id)
            ? this.state.wishLists[wishListId]['productId'].filter(
                ids => ids !== id
              )
            : this.state.wishLists[wishListId]['productId'].concat(id),
        }

        wishlist = { ...wishlist, productCount: wishlist['productId'].length }
        newWishListObject = {
          ...this.state.wishLists,
          [wishListId]: { ...wishlist },
        }
        this.setState({
          wishLists: newWishListObject,
          productListMap: this.mapProductsToLists(
            objectValues(newWishListObject)
          ),
        })
        return 'Resolved'
      })
      .catch(() => {
        return 'Rejected'
      })
  }
  updateWishlists(id, wishListsIDs) {
    return put(`product/${id}/wish-list`, {
      headers: headers(),
      body: JSON.stringify({
        wishListId: wishListsIDs,
      }),
    })
      .then(() => 'Resolved')
      .catch(() => {
        return 'Rejected'
      })
  }

  async addNewWishList(wishLists, productId, prevSelected) {
    // Comparing wishLists & prevSelected
    const itemsTobeDeleted = prevSelected.filter(
      item => wishLists.indexOf(item) === -1
    )
    const itemsTobeUpdated = wishLists.filter(
      item => prevSelected.indexOf(item) === -1
    )

    this.setState({
      isShownNotification: false,
    })

    const res = await this.updateWishlists(productId, wishLists)

    if (res === 'Resolved') {
      const newWishListObject = {}
      itemsTobeUpdated.length > 0 &&
        itemsTobeUpdated.map(eachItemId => {
          const object = {
            ...this.state.wishLists[eachItemId],
            productId: this.state.wishLists[eachItemId]['productId']
              ? this.state.wishLists[eachItemId]['productId'].concat(productId)
              : [productId],
          }
          object['productCount'] = object['productId'].length
          newWishListObject[eachItemId] = object
        })
      itemsTobeDeleted.length > 0 &&
        itemsTobeDeleted.map(eachItemId => {
          const object_1 = {
            ...this.state.wishLists[eachItemId],
            productId: this.state.wishLists[eachItemId]['productId']
              ? this.state.wishLists[eachItemId]['productId'].filter(
                  eachPid => eachPid !== productId
                )
              : [],
          }
          object_1['productCount'] = object_1['productId'].length
          newWishListObject[eachItemId] = object_1
        })
      const updatedWishListObject = {
        ...this.state.wishLists,
        ...newWishListObject,
      }
      this.setState({
        isShownNotification: true,
        wishLists: updatedWishListObject,
        productListMap: this.mapProductsToLists(
          objectValues(updatedWishListObject)
        ),
      })
      if (itemsTobeUpdated.length > 0) {
        return {
          action: 'Added',
          items: itemsTobeUpdated.map(item => this.state.wishLists[item].name),
        }
      }
      if (itemsTobeDeleted.length > 0) {
        return {
          action: 'Removed',
          items: itemsTobeDeleted.map(item => this.state.wishLists[item].name),
        }
      }
      // No Change
      return 'Rejected'
    } else {
      return res
    }
  }

  includes(product) {
    const { id } = product
    const { productListMap } = this.state

    return (
      Object.keys(productListMap).includes(id.toString()) &&
      productListMap[id].length > 0
    )
  }

  render() {
    const { children } = this.props
    const { isShownNotification } = this.state

    return (
      <Context.Provider
        value={{
          updateWishlists: this.updateWishlists,
          getProductWishLists: this.getProductWishLists,
          deleteWishList: this.deleteWishList,
          renameWishList: this.renameWishList,
          addNewWishList: this.addNewWishList,
          wishLists: this.state.wishLists,
          includes: this.includes,
          createWishList: this.createWishList,
          updateProductForSingleWishList: this.updateProductForSingleWishList,
          wishListsLoaded: this.state.wishListsLoaded,
        }}
      >
        {!!isShownNotification && (
          <NotificationBar
            message="Shopping lists updated!"
            onTimeout={() => this.setState({ isShownNotification: false })}
          />
        )}
        {children}
      </Context.Provider>
    )
  }
}

const WishlistProvider = props => (
  <AccountConsumer>
    {({ isLoggedIn, accountData }) => (
      <WishlistProviderWrapper
        {...props}
        accountData={accountData}
        isLoggedIn={isLoggedIn}
      />
    )}
  </AccountConsumer>
)

export default WishlistProvider

export const WishlistConsumer = Context.Consumer
