import React, { Component } from 'react'
import styled from 'styled-components'
import NoImg from '../../components/icons/NoImg'
import Image from "next/legacy/image"

/* istanbul ignore next */
const StyledImage = styled(Image)`
  background-color: #fff;
  object-fit: ${({ objectfit }) =>
    objectfit === 'true' ? 'contain' : 'cover'};
`

const NoImage = styled(NoImg)`
  width: 100%;
  height: 100%;
  max-width: 7rem;
  display: block;
  margin: auto;
`

class ProgressiveImage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoaded: false,
      replacedUrlLoaded: true,
    }
  }

  render() {
    const { isLoaded, replacedUrlLoaded, isBrokenImage } = this.state
    const {
      alt,
      title,
      src,
      originalSrc,
      imageQuality,
      shouldEagerLoad,
      shouldPreload,
      unoptimized,
      objectFitContain,
    } = this.props
    if (isBrokenImage) {
      return <NoImage />
    }
    // required by next/image
    /* istanbul ignore next */
    const myLoader = ({ src, width, quality }) => {
      return `${src}?w=${width}&q=${quality || 75}`
    }

    return (
      <StyledImage
        objectfit={String(objectFitContain)}
        src={replacedUrlLoaded ? src : originalSrc}
        loader={myLoader}
        alt={alt}
        title={title}
        layout="fill"
        priority={shouldPreload}
        unoptimized={unoptimized}
        loading={shouldEagerLoad ? 'eager' : 'lazy'}
        quality={imageQuality}
        onLoad={() => {
          this.setState({
            isLoaded: true,
          })

          if (this.props.onLoad) {
            this.props.onLoad()
          }
        }}
        onError={() => {
          if (originalSrc !== src) {
            if (originalSrc && this.state.replacedUrlLoaded && !isLoaded) {
              this.setState({
                replacedUrlLoaded: false,
                isBrokenImage: true,
              })
            } else {
              this.setState({ isLoaded: false })
              if (this.props.onLoad) {
                this.props.onLoad()
              }
            }
          } else {
            this.setState({ isLoaded: false, isBrokenImage: true })
          }
        }}
      />
    )
  }
}

ProgressiveImage.defaultProps = {
  width: '100%',
  height: '100%',
}

export default ProgressiveImage
