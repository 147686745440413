let instance = null

class SprigService {
  constructor() {
    if (!instance) {
      instance = this
    }
    return instance
  }

  queue = []
  attributesQueue = []
  userId = ''

  /*
		To verify whether sprig is currently supported or not?

		All methods consume this function to store events and attributes in two different queue
		until sprig is available. Once sprig is available we will push these events and attributes
		to sprig
	*/
  isSupported = () => {
    try {
      if (typeof window.Sprig === 'function') {
        if (this.userId !== '') {
          window.Sprig('setUserId', this.userId)
          this.userId = ''
        }
        if (this.attributesQueue.length > 0) {
          window.Sprig('setAttributes', ...this.attributesQueue)
          this.attributesQueue = []
        }

        if (this.queue.length > 0) {
          while (this.queue.length > 0) {
            const event = this.queue.pop()
            const { eventName, eventProperties = {} } = event
            window.Sprig('track', eventName, eventProperties)
          }
        }
        return true
      } else {
        return false
      }
    } catch (e) {
      return false
    }
  }

  /*
		This is method is used to track the events.
	 */
  track = (eventName, eventProperties = {}) => {
    if (this.isSupported()) {
      window.Sprig('track', eventName, eventProperties)
    } else {
      this.queue.unshift({
        eventName,
        eventProperties,
      })
    }
  }

  /*
		This is method is used to process the account data. It will send userId and link status
	 */
  pushAccountData = accountData => {
    if (!accountData) {
      return
    }
    if (this.isSupported()) {
      window.Sprig('setUserId', accountData.id.toString())
    } else {
      this.userId = accountData.id.toString()
    }
    this.pushAttributes(
      'SPRIG_PARAM_IS_LINK_MEMBER',
      !!accountData?.association?.linkplus
    )
  }

  /*
		This is method is used to push sprig attributes.
	 */
  pushAttributes = (attributes, value) => {
    if (this.isSupported()) {
      window.Sprig('setAttribute', attributes, value)
    } else {
      this.attributesQueue.unshift({
        [attributes]: value,
      })
    }
  }
}

export default new SprigService()
