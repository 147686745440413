let instance = null

//In anonymous mode of browsers, we are unable to save data in localStorage
//so we need to replicate localStorage and keep all changes in local data
class LocalStorageService {
  constructor() {
    if (!instance) {
      instance = this
    }

    return instance
  }

  currentLocalStorage = {}

  isSupported = () => {
    try {
      const key = '__this_key_should_not_use_anywhere__'
      const value = '__this_value_should_not_use_anywhere__'
      if (typeof window === 'undefined' || !window.localStorage) {
        return false
      }
      window.localStorage.setItem(key, value)
      window.localStorage.removeItem(key)
      return true
    } catch (e) {
      return false
    }
  }

  getItem = key => {
    if (this.isSupported()) {
      //if the local storage data is undefined, it will try to get data from current object
      //this case possibly happens in unit tests
      return window.localStorage.getItem(key) || this.currentLocalStorage[key]
    }
    return this.currentLocalStorage[key]
  }

  setItem = (key, value) => {
    if (this.isSupported()) {
      window.localStorage.setItem(key, value)
    }
    this.currentLocalStorage[key] = value
  }

  removeItem = key => {
    if (this.isSupported()) {
      window.localStorage.removeItem(key)
    }
    delete this.currentLocalStorage[key]
  }

  clear = () => {
    if (this.isSupported()) {
      window.localStorage.clear()
    }
    this.currentLocalStorage = {}
  }

  length = () => {
    if (this.isSupported()) {
      return window.localStorage.length
    }
    return Object.keys(this.currentLocalStorage).length
  }
}

export default new LocalStorageService()
