const format = require('date-fns/format')
const setHours = require('date-fns/set_hours')
const setMinutes = require('date-fns/set_minutes')
const _get = require('lodash/get')
const _isEmpty = require('lodash/isEmpty')
const getHours = require('date-fns/get_hours')
const parse = require('date-fns/parse')
/**
 * Map WCS urls to ZS urls
 */
const MAPPED_URLS = {
  PromotionCategoryView: {
    promotionPage: 'true',
    storeId: '10151',
    catalogId: '10201',
    promoCategory: ['PWP'],
  },
  '/PromotionCategoryView?promotionPage=true&storeId=10151&catalogId=10201&promoCategory=PWP': true, // Page: PWP
  '/webapp/wcs/stores/servlet/en/fairprice/weekly-deals': true, // Page: Weekly Deals
  '/3-day-specials': true, // Page: 3 Day Specials
  '/2-day-specials': true, // Page: 2 Day Specials
  '/webapp/wcs/stores/servlet/en/fairprice/1-day-specials': true, // Page:1 Day Specials
  '/webapp/wcs/stores/servlet/en/fairprice/value-deals': true, // Page: Value Deals
  '/webapp/wcs/stores/servlet/en/fairprice/carton-deals': true, // Page: Carton Deals
  '/GWP': true, // Page:Free Gift with Purchase
  '/webapp/wcs/stores/servlet/en/fairprice/clearance-sale': true, // Page: Clearance Sale
}

/**
 * convert query string into an object<key, value>
 *
 */
const convertQueryStringToObject = queryString => {
  const object = {}
  if (queryString) {
    const parts = queryString.split('&')
    parts.forEach(pair => {
      const [key, value] = pair.split('=')
      if (value) {
        object[key] = decodeURIComponent(value)
      }
    })
  }
  return object
}

const checkForMappedUrl = (url, categorySlug, queryString) => {
  // check if the url matches wholly
  if (MAPPED_URLS[url.replace(/[/&?]$/g, '')]) {
    return true
  }

  // check if the path and query parameters are as expected in any order
  if (
    MAPPED_URLS[categorySlug] &&
    MAPPED_URLS[categorySlug] instanceof Object
  ) {
    const params = convertQueryStringToObject(queryString)

    // console.log('params', params)
    if (Object.keys(params).length === 0) {
      return false
    }

    const expectedQueryParams = MAPPED_URLS[categorySlug]
    let matched = true
    for (const key in params) {
      if (
        expectedQueryParams[key] && Array.isArray(expectedQueryParams[key])
          ? !expectedQueryParams[key].includes(params[key])
          : expectedQueryParams[key] !== params[key]
      ) {
        matched = false
        break
      }
    }
    return matched
  }
  return false
}

const formatDate = (currentFormat, expectedFormat = 'D-MMM-YYYY') => {
  return format(currentFormat, expectedFormat)
}

const convert24to12Format = (currentDate, timestamp = '0:0') => {
  const tempDate = new Date(currentDate)
  const afterAddedHours = setHours(tempDate, timestamp.split(':')[0])
  const afterAddedMinutes = setMinutes(afterAddedHours, timestamp.split(':')[1])
  return format(afterAddedMinutes, 'hh:mm A')
}

const generateQueryString = (object, shouldNotDecode = false) => {
  if (!object || Object.keys(object).length === 0) {
    return ''
  }

  return (
    '?' +
    Object.keys(object)
      .filter(key => object[key])
      .map(
        key =>
          `${key}=${
            shouldNotDecode
              ? encodeURIComponent(object[key])
              : decodeURIComponent(encodeURIComponent(object[key]))
          }`
      )
      .join('&')
  )
}

/* START: NE Digital ID */
const getCurrentHost = () => {
  const urlParts = window.location.href.split('/')
  return `${urlParts[0]}//${urlParts[2]}`
}

// getting phone value from user data
// old flow: accountData.defaultPhone.phone
// new flow: accountData.country_code and accountData.phone_number
const getDefaultPhone = (accountData, withCountryCode = true) => {
  const countryCode = _get(accountData, 'country_code') || ''
  const phoneNumber = _get(accountData, 'phone_number') || ''
  if (!withCountryCode) {
    return phoneNumber
  }
  return `+${countryCode}${phoneNumber}`
}

const isPhoneEmpty = phoneNumber => {
  // phone number returned from API may contain legacy/migrated values like this
  return phoneNumber === '' || phoneNumber === '00000000'
}

// getting email value from user data
// old flow: accountData.defaultEmail.email
// new flow: accountData.email
const getEmail = accountData => {
  return (
    _get(accountData, 'email') ||
    (_get(accountData, 'emails') && _get(accountData, 'emails') instanceof Array
      ? _get(accountData, 'emails')[0]?.email
      : '')
  )
}
/* END: NE Digital ID */

const getHandlingTimeDisplayText = (
  details = {},
  currentStoreId = '',
  enabledStoreHandlingTime = false
) => {
  let handlingTimeDisplayText = ''
  let handlingTimeTrackingText = ''
  let deltime = ''
  if (enabledStoreHandlingTime) {
    const sellerStoresData =
      !_isEmpty(details) && details.seller && details.seller.sellerStores
        ? details.seller.sellerStores
        : {}
    if (!_isEmpty(sellerStoresData)) {
      const currentStoreData = sellerStoresData[currentStoreId] || {}
      const orderSlot = (currentStoreData && currentStoreData.orderSlot) || ''
      if (currentStoreData.handlingDays > 1 && orderSlot) {
        const orderSlotDate = orderSlot.slice(0, 10)
        handlingTimeDisplayText = `Only available from ${format(
          new Date(orderSlotDate),
          'ddd, D MMM'
        )}`
        handlingTimeTrackingText = currentStoreData.handlingDays
        deltime = `${format(new Date(orderSlotDate), 'ddd, D MMM')}`
      }
    }
  } else {
    const { handlingDays, leadTime } = details
    let noOfHandlingDays = leadTime || handlingDays || 0

    const currentHour = getHours(new Date())
    //a new logic for handling days
    //12am to 10pm (...available after 3 days)
    //10pm to 12am (...available after 4 days)
    if (noOfHandlingDays > 0) {
      noOfHandlingDays += currentHour >= 22 ? 1 : 0
    }
    handlingTimeDisplayText =
      noOfHandlingDays > 0
        ? `This product will only be available after ${noOfHandlingDays} days`
        : ''
    handlingTimeTrackingText =
      noOfHandlingDays > 0 ? `available after ${noOfHandlingDays} days` : ''
  }
  return {
    handlingTimeDisplayText,
    handlingTimeTrackingText,
    deltime,
  }
}

const isNewTag = (tags = []) => {
  return tags && tags.filter(tag => tag.name.toLowerCase() === 'new').length > 0
}

const isJwcTag = (tags = []) => {
  return tags && tags.filter(tag => tag.name.toLowerCase() === 'jwc').length > 0
}

const isBoughtBefore = (pastPurchases = {}) => {
  return !_isEmpty(pastPurchases) && pastPurchases.orderCount > 0
}

const isPastDate = expiresAt => {
  const formattedDate = format(parse(expiresAt), 'YYYY-MM-DD')
  const todaysDate = new Date()
  todaysDate.setHours(0, 0, 0, 0)
  return new Date(todaysDate) > new Date(formattedDate)
}

const getAppUrlLink = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera

  if (/android/i.test(userAgent)) {
    return 'https://play.google.com/store/apps/details?id=com.fairprice.mcomapp&hl=en_SG&gl=US'
  }

  if (/iPad|iPhone|iPod|AppleWebKit/.test(userAgent) && !window.MSStream) {
    return 'https://apps.apple.com/sg/app/fairprice-fast-reliable/id1012365118'
  }

  return ''
}

const getAllFilterCount = (appliedFilters = {}) => {
  if (!Object.keys(appliedFilters).length) {
    return 0
  }

  const result = []

  for (const value in appliedFilters) {
    result.push(...appliedFilters[value])
  }
  return result.length
}

const changeSearchType = (array, type, changeOnly) => {
  if (!array.length) {
    return []
  }

  return array.map(item => {
    if (changeOnly) {
      if (item.type === changeOnly) {
        return { ...item, type: type }
      } else {
        return item
      }
    }

    return { ...item, type: type }
  })
}

const getType = (array, type) => {
  if (!Array.isArray(array) || !array.length) {
    return undefined
  }
  const data = array.filter(item => item.type === type)

  if (data.length) {
    return data.map(item => item?.name).join()
  }

  return undefined
}

const getGA4SuggestedImpressions = (
  supplierPackageSuggestions = [],
  recentSearches = [],
  searchSuggestions = [],
  refinementItems = []
) => {
  const ga4SuggestionsImpressions = {
    ga4_suggested_campaign_impression:
      getType(supplierPackageSuggestions, 'campaign') ||
      getType(searchSuggestions, 'campaign'),
    ga4_suggested_keyword_impression: getType(
      supplierPackageSuggestions,
      'keyword'
    ),
    ga4_suggested_recent_impression: getType(recentSearches, 'pastSearch'),
    ga4_suggested_search_impression: getType(searchSuggestions, 'keyword'),
    ga4_suggested_refinement_impression: getType(refinementItems, 'keyword'),
  }

  return ga4SuggestionsImpressions
}

module.exports = {
  checkForMappedUrl,
  convertQueryStringToObject,
  formatDate,
  convert24to12Format,
  generateQueryString,
  getCurrentHost,
  getEmail,
  getDefaultPhone,
  isPhoneEmpty,
  getHandlingTimeDisplayText,
  isNewTag,
  isBoughtBefore,
  isJwcTag,
  isPastDate,
  getAppUrlLink,
  getAllFilterCount,
  changeSearchType,
  getType,
  getGA4SuggestedImpressions,
}
