import React from 'react'
import { withRouter } from 'next/router'
import _isEqual from 'lodash/isEqual'
import _sortBy from 'lodash/sortBy'
import dynamic from 'next/dynamic'
import styled from 'styled-components'
const NdsThemeProvider = dynamic(() =>
  import('@ntuctech/devex-tangram/Theme/NdsThemeProvider')
)
const Toast = dynamic(() => import('@ntuctech/devex-tangram/Toast'))
import SvgSuccess from '@ntuctech/devex-tangram/Icon/SvgSuccess'
import SvgAlert from '@ntuctech/devex-tangram/Icon/SvgAlert'
import { AccountConsumer } from '../AccountProvider'
import { CheckoutAddressConsumer } from '../CheckoutAddressProvider'
import { CartConsumer } from '../CartProvider'
import { get, parseAPIError, post, headers } from '../../lib/fetch'
import { getProperAddressKey } from '../../lib/addressSearch'
import { getDefaultCard } from '../../lib/utils'
import { getConfigFor, getExpConfig } from '../../utils/configService'
import { SPLIT_FEATURES } from '../../utils/constants'
import { IdleQueue } from '../../lib/idlize/IdleQueue'
import { getPromoRemovedLabel, getRemovedCoupons } from './utils'
const queue = new IdleQueue()

const StyledToaster = styled(Toast)`
  ${props => props.isVoucherApplied && 'z-index: 999999999999999 !important'}
`

export const Context = React.createContext({
  appliedPromocodes: [],
  availablePromocodes: [],
  apply: () => {},
  remove: () => {},
  loading: false,
})

const TOASTER_DISPOSAL_TIME = 4000
const APPLIED_VOUCHERS_GUEST_USER = 'appliedVouchersGuestUser'
const APPLIED_VOUCHER_DATA = 'appliedVoucherData'

class PromocodeProviderWrp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      availablePromocodes: [],
      appliedPromocodes: [],
      removedCouponDiscounts: [],
      loading: false,
      promoRemovedLabel: '',
    }

    this.apply = this.apply.bind(this)
    this.remove = this.remove.bind(this)
    this.setPromoCodes = this.setPromoCodes.bind(this)
  }

  addExtraCouponAttributes = coupons => {
    const gmcEnablingSplit = getExpConfig(SPLIT_FEATURES.GMC_ENABLING)
    const { couponCode, couponText } = JSON.parse(
      gmcEnablingSplit?.config || '{}'
    )
    return coupons.map(couponValue => {
      const updatedCouponValue = {
        ...couponValue,
        canBeRemoved: couponCode !== couponValue.coupon.couponCode,
      }
      if (couponCode === couponValue.coupon.couponCode) {
        updatedCouponValue.coupon.aliasName = couponText
      }
      return updatedCouponValue
    })
  }

  generateRequestBody = async ({ checkoutParams, couponCodes }) => {
    const { checkoutAddress, totalPrice, accountData, cartReqObj } = this.props

    const orderAmount = totalPrice().totalAmount

    let cardId = checkoutParams.cardId
    if (!cardId && accountData && Object.keys(accountData).length) {
      const defaultCard = await getDefaultCard()
      cardId = defaultCard?.id
    }

    return {
      couponCodes,
      ...getProperAddressKey(checkoutAddress, accountData),
      ...(cartReqObj ? cartReqObj() : {}),
      orderAmount,
      cardId: cardId,
      preferredDate: checkoutParams.preferredDate,
      preferredSlotId: checkoutParams.preferredSlotId,
    }
  }

  async remove(coupon) {
    const { appliedPromocodes } = this.state
    let updatedPromocodes = [...appliedPromocodes].filter(
      promocode => promocode.coupon?.couponCode !== coupon.couponCode
    )
    if (Array.isArray(coupon)) {
      updatedPromocodes = appliedPromocodes?.filter(
        promocode => !coupon.includes(promocode.coupon?.couponCode)
      )
    }
    this.setState({
      appliedPromocodes: updatedPromocodes || [],
    })

    if (!this.props.isLoggedIn) {
      const appliedCoupons =
        updatedPromocodes &&
        updatedPromocodes.map(
          promo =>
            promo.coupon.couponCode && promo.coupon.couponCode.toUpperCase()
        )
      localStorage?.setItem(
        APPLIED_VOUCHERS_GUEST_USER,
        JSON.stringify(appliedCoupons)
      )
      const appliedVoucherDataArr = JSON.parse(
        localStorage.getItem(APPLIED_VOUCHER_DATA)
      )
      const filteredData = appliedVoucherDataArr?.filter(promocode =>
        appliedCoupons.includes(promocode?.code)
      )

      localStorage?.setItem(APPLIED_VOUCHER_DATA, JSON.stringify(filteredData))
    }
    return { type: 'Success' }
  }

  async apply(voucherCode, checkoutParams = {}) {
    this.setState({ loading: true })
    const { handleSyncCart } = this.props

    const requestBody = await this.generateRequestBody({
      couponCodes: voucherCode,
      checkoutParams,
    })

    return post(`checkout`, {
      body: JSON.stringify(requestBody),
      headers: headers(),
    })
      .then(response => {
        this.setState({ loading: false })
        const couponDiscounts = response.data.couponDiscounts
        let appliedCouponDiscounts =
          couponDiscounts && couponDiscounts.filter(coupon => coupon.applied)
        const notApplicableCouponDiscounts =
          couponDiscounts && couponDiscounts.filter(coupon => !coupon.applied)

        appliedCouponDiscounts = this.addExtraCouponAttributes(
          appliedCouponDiscounts
        )

        if (notApplicableCouponDiscounts.length === 0) {
          this.setState({
            appliedPromocodes: appliedCouponDiscounts || [],
          })
          response.data.cardId = checkoutParams?.cardId || ''
          handleSyncCart(response.data, requestBody)

          if (appliedCouponDiscounts?.length) {
            return { type: 'Success', message: '' }
          } else {
            return { type: 'Rejected', message: 'Coupon is not applicable' }
          }
        } else {
          return {
            type: 'Rejected',
            message: notApplicableCouponDiscounts,
          }
        }
      })
      .catch(error => {
        this.setState({ loading: false })
        return { type: 'Rejected', message: parseAPIError(error).message }
      })
  }

  setPromoCodes(promoCodes, isOrderPlacement) {
    const updatedPromoCodes = this.addExtraCouponAttributes(promoCodes)

    this.setState({
      appliedPromocodes: updatedPromoCodes || [],
      isOrderPlacement,
    })
    //if it's order placement, we need to reset isOrderPlacement state to avoid unexpected toasters after order placement
    if (isOrderPlacement) {
      setTimeout(() => {
        this.setState({ isOrderPlacement: false })
      }, TOASTER_DISPOSAL_TIME)
    }
  }

  componentDidMount() {
    const { accountData, isLoggedIn } = this.props
    const { id } = accountData || {}
    if (isLoggedIn) {
      queue.pushTask(() => {
        get(`coupon?customerId=${id}`, {
          headers: headers(),
        })
          .then(response => {
            const couponWallet = response.data && response.data.couponWallet
            this.setState({
              availablePromocodes: couponWallet,
            })
          })
          .catch(error => {
            this.setState({ error: { api: parseAPIError(error).message } })
          })
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { accountData, isLoggedIn, applyPromoCodes } = this.props
    const { isOrderPlacement } = this.state
    const { id } = accountData || {}
    if (isLoggedIn && !prevProps.isLoggedIn) {
      get(`coupon?customerId=${id}`, {
        headers: headers(),
      })
        .then(response => {
          const couponWallet = response.data && response.data.couponWallet
          this.setState({
            availablePromocodes: couponWallet,
          })
        })
        .catch(error => {
          this.setState({ error: { api: parseAPIError(error).message } })
        })
    }

    if (
      this.props.cartCouponDiscounts?.length !==
      prevProps.cartCouponDiscounts?.length
    ) {
      const removedCouponDiscounts = this.addExtraCouponAttributes(
        getRemovedCoupons({
          cartCouponDiscounts: this.props.cartCouponDiscounts,
          prevCartCouponDiscounts: prevProps.cartCouponDiscounts,
        })
      )
      const updatedCoupons = this.addExtraCouponAttributes(
        this.props.cartCouponDiscounts
      )
      this.setState({
        appliedPromocodes: updatedCoupons,
        removedCouponDiscounts: removedCouponDiscounts,
      })
    }

    const currentCouponCodes = this.state.appliedPromocodes.map(
      promocode => promocode.coupon.couponCode
    )

    const prevCouponCodes = prevState.appliedPromocodes.map(
      promocode => promocode.coupon.couponCode
    )

    //the promo disposal toaster should not be shown after order placement
    if (
      prevCouponCodes.length > 0 &&
      currentCouponCodes.length < prevCouponCodes.length &&
      !isOrderPlacement
    ) {
      const { isEnabled: isVoucherRemovalBannerEnabled } = getConfigFor(
        SPLIT_FEATURES.CHECKOUT_VOUCHER_REMOVAL_BANNER
      )
      const promoRemovedLabel = getPromoRemovedLabel({
        isVoucherRemovalBannerEnabled,
        removedCouponDiscounts: this.state.removedCouponDiscounts,
      })

      this.setState(
        {
          isPromoRemoved: true,
          isPromoAdded: false,
          promoRemovedLabel,
          removedCouponDiscounts: [],
        },
        () => {
          setTimeout(() => {
            this.setState({
              isPromoRemoved: false,
              promoRemovedLabel: '',
            })
          }, TOASTER_DISPOSAL_TIME)
        }
      )
    }

    if (currentCouponCodes.length > prevCouponCodes.length) {
      this.setState({ isPromoAdded: true, isPromoRemoved: false }, () => {
        setTimeout(() => {
          this.setState({ isPromoAdded: false })
        }, TOASTER_DISPOSAL_TIME)
      })
    }

    if (
      (currentCouponCodes.length > 0 || prevCouponCodes.length > 0) &&
      !_isEqual(_sortBy(currentCouponCodes), _sortBy(prevCouponCodes))
    ) {
      applyPromoCodes(currentCouponCodes)
    }
  }

  render() {
    const { children } = this.props
    const { isPromoRemoved, promoRemovedLabel, isPromoAdded } = this.state
    const { isEnabled: isAppliedVoucher } = getConfigFor(
      SPLIT_FEATURES.APPLIED_VOUCHERS
    )
    return (
      <Context.Provider
        value={{
          apply: this.apply,
          availablePromocodes: this.state.availablePromocodes,
          appliedPromocodes: this.state.appliedPromocodes,
          loading: this.state.loading,
          remove: this.remove,
          setPromoCodes: this.setPromoCodes,
        }}
      >
        {isPromoRemoved && (
          <NdsThemeProvider>
            <StyledToaster
              id="remove-toaster"
              inline
              type="alert"
              icon={<SvgAlert />}
              isVoucherApplied={isAppliedVoucher}
              message={promoRemovedLabel || 'Voucher has been removed'}
            />
          </NdsThemeProvider>
        )}
        {isPromoAdded && (
          <NdsThemeProvider>
            <Toast
              inline
              type="success"
              icon={<SvgSuccess />}
              message="Applied successfully!"
            />
          </NdsThemeProvider>
        )}

        {children}
      </Context.Provider>
    )
  }
}

const PromocodeProvider = props => (
  <AccountConsumer>
    {({ accountData, isLoggedIn }) => (
      <CheckoutAddressConsumer>
        {({ checkoutAddress }) => (
          <CartConsumer>
            {({
              cartReqObj,
              items,
              totalPrice,
              applyPromoCodes,
              handleSyncCart,
              couponDiscounts,
            }) => (
              <PromocodeProviderWrp
                {...props}
                accountData={accountData}
                isLoggedIn={isLoggedIn}
                checkoutAddress={checkoutAddress}
                cartReqObj={cartReqObj}
                items={items}
                totalPrice={totalPrice}
                applyPromoCodes={applyPromoCodes}
                handleSyncCart={handleSyncCart}
                cartCouponDiscounts={couponDiscounts}
              />
            )}
          </CartConsumer>
        )}
      </CheckoutAddressConsumer>
    )}
  </AccountConsumer>
)

export default withRouter(PromocodeProvider)

export const PromocodeConsumer = Context.Consumer
