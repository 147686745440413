import React from 'react'
import getConfig from 'next/config'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import Modal from '@ntuctech/devex-tangram/Modal'
import ModalTitle from '@ntuctech/devex-tangram/ModalTitle'
import ModalContent from '@ntuctech/devex-tangram/ModalContent'
import ModalActions from '@ntuctech/devex-tangram/ModalActions'
import Typography from '@ntuctech/devex-tangram/Typography'
import ButtonContained from '@ntuctech/devex-tangram/Button/ButtonContained'

import { GTM_ENV_VAR } from '../../lib/eventTrackerFunction'
import { AMP_EVENT_NAME } from '../../lib/amplitude'

const ModalSc = styled(Modal)`
  border-radius: 0.25rem;
`

const ModalActionsSc = styled(ModalActions)`
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
`

const ButtonContainedSc = styled(ButtonContained)`
  width: 100%;
  margin: 0;
  :not(:last-child) {
    margin-bottom: 1rem;
  }
`

const {
  publicRuntimeConfig: { FPG_SINGLE_ACCOUNT_PAGE_DOMAIN_NEW },
} = getConfig()

function LinkMergingPopup(props) {
  const {
    openPopup,
    handleClosePopup,
    handleRefreshAuthToken,
    isMatchTo,
    track,
  } = props
  const [connectloading, setConnectloading] = React.useState(false)
  const [cancelloading, setCancelloading] = React.useState(false)

  const router = useRouter()

  React.useEffect(() => {
    if (openPopup) {
      track({
        [GTM_ENV_VAR.AMPLITUDE_EVENT_NAME]: AMP_EVENT_NAME.NUDGE_SHOWN,
        [GTM_ENV_VAR.EVENT_ACTION]: AMP_EVENT_NAME.NUDGE_SHOWN,
        [GTM_ENV_VAR.MATCH_TYPE]: isMatchTo,
      })
    }
  }, [openPopup, isMatchTo, track])

  const onClickConnect = () => {
    setConnectloading(true)
    handleRefreshAuthToken(
      () =>
        router.push(
          `${FPG_SINGLE_ACCOUNT_PAGE_DOMAIN_NEW}linkpoints/restore?link_account_matching_confidence=no_match`
        ),
      setConnectloading
    )
    track({
      [GTM_ENV_VAR.AMPLITUDE_EVENT_NAME]: AMP_EVENT_NAME.NUDGE_START_CONNECT,
      [GTM_ENV_VAR.EVENT_ACTION]: AMP_EVENT_NAME.NUDGE_START_CONNECT,
      [GTM_ENV_VAR.MATCH_TYPE]: isMatchTo,
    })
  }

  const handleCloseFunction = () => {
    setCancelloading(true)
    handleRefreshAuthToken(handleClosePopup, setCancelloading)
    track({
      [GTM_ENV_VAR.AMPLITUDE_EVENT_NAME]: AMP_EVENT_NAME.NUDGE_DISMISSED,
      [GTM_ENV_VAR.EVENT_ACTION]: AMP_EVENT_NAME.NUDGE_DISMISSED,
      [GTM_ENV_VAR.MATCH_TYPE]: isMatchTo,
    })
  }

  return (
    <ModalSc open={openPopup}>
      <ModalTitle>
        <Typography variant="h5">Already have a Link account?</Typography>
      </ModalTitle>
      <ModalContent>
        <Typography variant="body2">
          Connect your existing Link account(s) to earn Linkpoints under a
          single account
        </Typography>
      </ModalContent>
      <ModalActionsSc>
        <ButtonContainedSc onClick={onClickConnect} loading={connectloading}>
          Connect now
        </ButtonContainedSc>
        <ButtonContainedSc
          color="grey"
          onClick={handleCloseFunction}
          loading={cancelloading}
        >
          Maybe later
        </ButtonContainedSc>
      </ModalActionsSc>
    </ModalSc>
  )
}

export default LinkMergingPopup
