import { getAllConfig } from '../utils/configService'

const getSubstitutionConfigs = internals => {
  try {
    const keys = Object.keys(internals).filter(key =>
      key.includes('web_checkout_product_sub')
    )
    const resp = {}
    keys.forEach(key => {
      const config = internals[key]
      const storeID = key.split('-')[1]
      if (config.treatment !== 'control') {
        resp[storeID] = config
      }
    })

    return resp
  } catch (err) {
    return {}
  }
}

const checkSubstitutionDisplay = storeId => {
  if (!storeId) {
    return false
  }
  let isProductSubEnabled = false
  const productSubConfig = getSubstitutionConfigs(getAllConfig())
  if (
    productSubConfig.hasOwnProperty(storeId) &&
    productSubConfig[storeId].treatment === '1'
  ) {
    isProductSubEnabled = true
  }
  return isProductSubEnabled
}

module.exports = {
  getSubstitutionConfigs,
  checkSubstitutionDisplay,
}
