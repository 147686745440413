export const CHECKOUT_PAYMENT_METHODS = {
  ONLINE: {
    text: 'Credit/Debit Card',
    value: 'ONLINE',
  },
  GEBIZ: {
    text: 'GeBIZ',
    value: 'GEBIZ',
  },
  EINVOICE: {
    text: 'Vendors@Gov',
    value: 'EINVOICE',
  },
  CREDITTERM: {
    text: 'Credit term',
    value: 'CREDITTERM',
  },
  GOOGLE_PAY: {
    text: 'Google Pay',
    value: 'GOOGLE_PAY',
  },
  APPLE_PAY: {
    text: 'Apple Pay',
    value: 'APPLE_PAY',
  },
  CORPBILLING: {
    text: 'QuickBuy@SGov',
    value: 'CORPBILLING',
  },
}

export const BUSINESS_STATUSES = {
  NONE: 'NONE',
  PARTIAL: 'PARTIAL',
  FULL: 'FULL',
}

export const DEFAULT_BENEFIT_CONFIGS = {
  benefits: [
    'UNLIMITED free delivery*',
    'UNLIMITED waiver of service fee**',
    '2X LinkPoints with bonus earn rate',
    'Exclusive promotions\n(Over $10 savings monthly)',
    'Lifestyle deals with partner brands',
  ],
  isCheckedMemberForBenefitIndices: [2],
  benefitHyperlinks: [
    '',
    '',
    '',
    'https://www.fairprice.com.sg/tag/digital-club-promos',
    'https://www.fairprice.com.sg/promo/digital-club-partner-deals/?utm_source=account-dc&utm_medium=in-app&utm_term=digital_club&utm_content=partnerdeals',
  ],
  criteria: [
    {
      prefix: '',
      format: '',
      suffix: '*$79 and above',
    },
    {
      prefix: '',
      format: '',
      suffix: '**$100 and above',
    },
  ],
  criteriaNote: 'For orders',
  dcLogo:
    'https://media.nedigital.sg/fairprice/images/375a6afa-0c63-4210-8f62-de3753b0e028/dc-logo.png',
  promotionBanners: [
    'https://media.nedigital.sg/fairprice/images/6c60ac8c-9a25-45ab-897a-42a1ed152885/dc_banner.jpg',
    'https://media.nedigital.sg/fairprice/images/6c60ac8c-9a25-45ab-897a-42a1ed152885/dc_banner.jpg',
    'https://media.nedigital.sg/fairprice/images/6c60ac8c-9a25-45ab-897a-42a1ed152885/dc_banner.jpg',
  ],
  nonMemberText: 'Cancel anytime, no obligations',
}

export const GMC_COOKIE_KEYS = {
  OptedIn: 'gmc_oi',
  Dismissed: 'gmc_d',
}

export const ZKF_OFFER_IDENTIFIER = 'SFXGSD'

export const MEMBERSHIP_TYPES = {
  justWineClub: 'just_wine_club',
  fairpriceDigitalClub: 'fairprice_digital_club',
}

export const TRUST_CARD_MESSAGES = {
  body: 'Check out with Trust, earn up to 21% savings. T&Cs apply.',
  payHeader: 'Pay with your Trust card',
  addHeader: 'Add your Trust card',
  addBody:
    'Add and check out with Trust, earn up to 21% in savings. T&Cs apply.',
  accountAddHeader: 'Hello, Trust cardholder',
}
