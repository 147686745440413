import React from 'react'
import styled from 'styled-components'

import Modal from '@ntuctech/devex-tangram/Modal'
import ModalContent from '@ntuctech/devex-tangram/ModalContent'
import ModalActions from '@ntuctech/devex-tangram/ModalActions'
import Box from '@ntuctech/devex-tangram/Box'
import Typography from '@ntuctech/devex-tangram/Typography'
import Link from '@ntuctech/devex-tangram/Link'
import ButtonContained from '@ntuctech/devex-tangram/Button/ButtonContained'

import GmcSvg from '../icons/svg/gmc-popup.svg'
import { SCREEN_SIZE } from '../../lib/Media'

const StyledModal = styled(Modal)`
  padding: 1rem 2.5rem 4.75rem 2.5rem;
  width: 28rem;

  a {
    white-space: pre;
  }

  ${SCREEN_SIZE.Below.MobileL} {
    padding: 1.5rem;
  }
`
const GmcIllust = styled.div`
  display: block;
  margin: auto;
  width: 14.5rem;
  height: 10.5rem;
  background-image: url(${GmcSvg});
`
const ActionRow = styled(ModalActions)`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: 0;

  & > button {
    margin-left: 0.75rem;
    margin-right: 0;
  }

  ${SCREEN_SIZE.Below.MobileL} {
    flex-wrap: wrap;
    margin-top: 2rem;

    & > button {
      width: 100%;
      margin: 0;
    }
    & > button:last-child {
      margin-top: 0.5rem;
    }
  }
`
const DesktopSpacerSC = styled.div`
  width: 100%;
  height: ${props => props.height}rem;
`
const DesktopSpacer = ({ height, ...others }) => (
  <DesktopSpacerSC className="ndsHidden-xs-down" height={height} {...others} />
)

const GmcPopup = props => {
  const { handleGmcSubmit } = props

  return (
    <StyledModal data-testid="groupMarketingConsentPopup" open>
      <Box mb={3}>
        <GmcIllust />
      </Box>
      <DesktopSpacer height={1} />
      <ModalContent>
        <Box mb={1.5}>
          <Typography variant="h5">
            Want $10 off your first online grocery order?
          </Typography>
        </Box>
        <DesktopSpacer height={0.25} />
        <Box mb={2}>
          <Typography variant="body3">
            Get your voucher now when you opt in to receive{' '}
            <Link
              href="https://help.fairprice.com.sg/hc/en-us/articles/4403577723673"
              target="_blank"
              title="Exclusive Offers"
            >
              exclusive offers
            </Link>
            {`, Linkpoints and lucky draws from `}
            <Link
              href="https://help.fairprice.com.sg/hc/en-us/articles/4403577720473"
              target="_blank"
              title="FairPrice Group"
            >
              FairPrice Group
            </Link>{' '}
            and its partners via email, text messages and phone calls.
          </Typography>
        </Box>
      </ModalContent>
      <DesktopSpacer height={1.5} style={{ borderTop: '1px solid #eaeaea' }} />
      <ActionRow>
        <ButtonContained
          onClick={() => {
            handleGmcSubmit(true)
          }}
          data-testid="gmcButtonYes"
        >
          Yes, opt in
        </ButtonContained>
        <ButtonContained
          color="grey"
          onClick={() => {
            handleGmcSubmit(false)
          }}
          data-testid="gmcButtonNo"
        >
          Maybe later
        </ButtonContained>
      </ActionRow>
    </StyledModal>
  )
}

export default GmcPopup
