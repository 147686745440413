import Router from 'next/router'

export const verifyPagePermission = (context, Component, account) => {
  const { isAccessibleBy } = Component
  return typeof isAccessibleBy !== 'function'
    ? { isAccessible: true }
    : isAccessibleBy(context, Component, account)
}

export const user = () => (context, Component, account) => {
  const isUser = account !== undefined
  const userOrRedirectTo = `/login?redirect=${encodeURIComponent(
    context.asPath
  )}`
  return {
    isAccessible: isUser,
    redirect: () => redirectTo(context.res, userOrRedirectTo),
  }
}

export const guest = () => (context, Component, account) => {
  const isGuest = account === undefined
  const guestOrRedirectTo = '/accounts'
  return {
    isAccessible: isGuest,
    redirect: () => redirectTo(context.res, guestOrRedirectTo),
  }
}

const redirectTo = (res, url) => {
  if (res) {
    res.writeHead(302, {
      Location: url,
    })
    res.end()
  } else {
    Router.push(url)
  }
}
