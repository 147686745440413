const processAvailableStores = availableStores => {
  if (availableStores && availableStores.length) {
    const processedStores = []
    availableStores.forEach(store => {
      delete store.metaData
      delete store.businessHours
      delete store.tierSetupId
      delete store.languages
      delete store.hasDeliveryHub
      processedStores.push(store)
    })

    return processedStores
  } else {
    return availableStores
  }
}

export default processAvailableStores
