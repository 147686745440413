import React from 'react'
import styled from 'styled-components'
import Popup from '../Popup'
import Text from '../Text'

const Container = styled.div`
  width: 20.5rem;
  border-radius: 4px;
  box-shadow: 0 13px 15px 0 rgba(0, 0, 0, 0.12);
  border: solid 1px #eaeaea;
  background-color: #ffffff;
  padding: 1rem 1rem 2rem 1rem;

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

const Message = styled(Text)`
  margin-bottom: 1.5rem;
`

const Button = styled.button`
  cursor: pointer;
  outline: none;
  padding: 0;
  border: none;
  width: 100%;
  height: 3.5rem;
  border-radius: 1.75rem;
  background-color: #1557bf;
`

const ProductQuantityAdjustmentMessage = props => {
  const { message, onConfirm } = props
  return (
    <Popup>
      <Container>
        <Message as="h1" size="xl" weight="bold" color="#333333">
          {message}
        </Message>
        <Button onClick={onConfirm}>
          <Text size="large" weight="bold" color="#ffffff">
            Okay, got it
          </Text>
        </Button>
      </Container>
    </Popup>
  )
}

export default ProductQuantityAdjustmentMessage
