export function objectValues(items) {
  if (!Object.values) {
    Object.values = function(obj) {
      return Object.keys(obj).map(function(e) {
        return obj[e]
      })
    }
  }

  return Object.values(items)
}
