import { useCallback, useEffect, useState } from 'react'
import SessionStorageService from '../../utils/SessionStorageService'
import LocalStorageService from '../../utils/LocalStorageService'

const getInitialStateFromStorage = (name, defaultValue, driver) => {
  try {
    const unparsedValue = driver.getItem(name)
    if (!unparsedValue) {
      return defaultValue
    }
    const parsedValue = JSON.parse(unparsedValue)
    if (!parsedValue) {
      return defaultValue
    }

    return parsedValue
  } catch (e) {
    return defaultValue
  }
}

export const useWebStorage = (name, defaultValue, driver) => {
  const [state, _setState] = useState(
    getInitialStateFromStorage(name, defaultValue, driver)
  )
  const setState = useCallback(
    newState => {
      if (newState === null || newState === undefined) {
        driver.removeItem(name)
        _setState(defaultValue)
      } else {
        driver.setItem(name, JSON.stringify(newState))
        _setState(newState)
      }
    },
    [defaultValue, driver, name]
  )
  return [state, setState]
}

const useStorageState = (name, defaultValue) => {
  const [localState, setLocalState] = useWebStorage(
    name,
    defaultValue,
    LocalStorageService
  )
  const [sessionState, setSessionState] = useWebStorage(
    name,
    defaultValue,
    SessionStorageService
  )

  const state = sessionState.value ? sessionState : localState

  const setState = useCallback(
    (newState, opts) => {
      const { persist } = opts || {
        persist: false,
      }

      if (
        persist ||
        newState === null ||
        newState === undefined ||
        !!localState.value
      ) {
        setLocalState(newState)
      }
      setSessionState(newState)
    },
    [localState.value, setLocalState, setSessionState]
  )
  return [state, setState]
}

export const useSyncableSessionStorage = ({ name, channelName }) => {
  const [channel, setChannel] = useState(null)
  useEffect(() => {
    if (typeof window !== 'undefined' && window.BroadcastChannel) {
      setChannel(new window.BroadcastChannel(channelName))
    }
  }, [channelName])

  const [syncableState, setSyncableState] = useStorageState(name, {
    t: 0,
    value: null,
  })

  // on mounted
  useEffect(() => {
    channel?.postMessage({
      type: 'INITIAL_LOAD',
    })
  }, [channel])
  useEffect(() => {
    function handleMessage(event) {
      switch (event.data.type) {
        case 'INITIAL_LOAD':
          channel.postMessage({
            type: 'STATE_UPDATE',
            ...syncableState,
          })
          break
        case 'CLEAR_STATE':
          setSyncableState(null)
          break
        case 'STATE_UPDATE':
          if (event.data?.t > syncableState.t && event.data?.value) {
            // set local to new state
            setSyncableState({
              value: event.data.value,
              t: event.data.t,
            })
          }
          break
      }
    }

    channel?.addEventListener('message', handleMessage)
    return () => {
      channel?.removeEventListener('message', handleMessage)
    }
  }, [channel, setSyncableState, syncableState])

  const setState = useCallback(
    (newValue, opts) => {
      if (!newValue) {
        setSyncableState(null)
        // broadcast clear state
        channel?.postMessage({ type: 'CLEAR_STATE' })
      } else {
        const timestamp = new Date().getTime()
        const newSyncableState = {
          t: timestamp,
          value: newValue,
        }
        // set to local
        setSyncableState(newSyncableState, opts)
        // broadcast to other
        channel?.postMessage({ type: 'STATE_UPDATE', ...newSyncableState })
      }
    },
    [channel, setSyncableState]
  )

  return [syncableState.value, setState]
}
