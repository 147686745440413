export const isAnyCartItemsInCategories = (cartItems, categoryIds) => {
  return !!Object.values(cartItems || {}).some(
    item =>
      item.isChecked &&
      item.count &&
      (categoryIds || []).includes(item.product?.primaryCategory?.id)
  )
}

export const createCartSignature = cartItems => {
  return Object.keys(cartItems || {})
    .sort((a, b) => Number(a) - Number(b))
    .filter(itemId => {
      return cartItems[itemId]?.isChecked && cartItems[itemId]?.count
    })
    .map(itemId => {
      const item = cartItems[itemId]
      return [itemId, item?.count].join(',')
    })
    .join('|')
}
