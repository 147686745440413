const constants = require('./constants')

const getDomainForCookies = domainArg => {
  let domainTreat

  if (!domainArg) {
    if (typeof window === 'undefined') {
      return ''
    } else {
      domainTreat = window.location.hostname
    }
  } else {
    domainTreat = domainArg
  }

  let cookieDomain = ''

  // match IP addresses
  const ipRegex =
    /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/g

  if (
    typeof domainTreat !== 'undefined' &&
    domainTreat !== 'localhost' &&
    !ipRegex.test(domainTreat)
  ) {
    const removeSubdomain = wholeDomain => {
      var isCom = wholeDomain.match(/\.com\./)
      wholeDomain = wholeDomain.split('.')
      wholeDomain = wholeDomain.slice(isCom ? -3 : -2)
      wholeDomain = wholeDomain.join('.')
      return wholeDomain
    }
    cookieDomain = `.${removeSubdomain(domainTreat)}`
  }
  return cookieDomain
}

const authTokenCookiOptions = domain => {
  return {
    path: '/',
    maxAge: constants.AUTH_COOKIE_MAX_AGE_IN_S,
    sameSite: 'lax',
    domain: domain,
    secure: true,
  }
}

const authTokenCookiOptionsForServer = domain => {
  return {
    ...authTokenCookiOptions(domain),
    maxAge: constants.AUTH_COOKIE_MAX_AGE_IN_MS,
  }
}

const clearAuthCookieForServer = (req, res, key) => {
  const cookieDomain = getDomainForCookies(req.hostname)
  const cookieOptions = authTokenCookiOptionsForServer(cookieDomain)
  res.clearCookie(key || constants.AUTH_TOKEN_KEY, cookieOptions)
}

const clearCookieForDY = ({ res }) => {
  /* istanbul ignore next */
  res.clearCookie('_dyid_server', {
    path: '/',
  })
  /* istanbul ignore next */
  res.clearCookie('_dyjsession', {
    path: '/',
  })
}

module.exports = {
  getDomainForCookies,
  authTokenCookiOptions,
  authTokenCookiOptionsForServer,
  clearAuthCookieForServer,
  clearCookieForDY,
}
