import { createContext } from 'react'

const AccountContext = createContext({
  accountData: {},
  update: () => {},
  login: () => {},
  logout: () => {},
  patchAccountData: () => {},
  isLoggedIn: false,
})

const { Consumer: AccountConsumer } = AccountContext

export { AccountConsumer, AccountContext }
