import { objectValues } from './polyfills'
import { get, PUBLIC_API_URL } from '../lib/fetch'

function getProperAddressKey(checkoutAddress, user) {
  const { location, ...others } = checkoutAddress
  const newAddressObj = { ...others, ...location }

  if (user && (!newAddressObj || !newAddressObj.address)) {
    return undefined
  }

  if (newAddressObj.type === 'pickup') {
    return { pickupLocationId: newAddressObj.pickingStoreId }
  } else {
    // if user is logged in and any saved address is selected, then send addressId else send address object
    if (newAddressObj.id && user) {
      return { addressId: newAddressObj.id }
    } else {
      return { address: newAddressObj }
    }
  }
}

function getValuesRecurssivelyIntoString(obj) {
  let str = ''
  if (obj && typeof obj === 'object') {
    str = objectValues(obj).reduce((fullStr, val) => {
      if (typeof val === 'object') {
        fullStr += getValuesRecurssivelyIntoString(val)
      } else {
        fullStr += typeof val === 'string' ? val.toLowerCase() : val
      }
      return fullStr
    }, '')
  }

  return str
}

function addressSearch(address, query) {
  if (typeof query === 'string') {
    let addressString = ''
    addressString = getValuesRecurssivelyIntoString(address)
    return query ? addressString.indexOf(query.toLowerCase()) > -1 : true
  } else if (typeof query === 'object') {
    return address.latitude === query.lat || address.longitude === query.lng
  }
  return false
}

const FormattedAddress = ({ addressInfo, isUsingPincode = true }) => {
  const { address, floor, unit, pincode } = addressInfo
  let addr = address ? address.split('Singapore')[0] : ''
  addr = addr.trim()
  addr = addr[addr.length - 1] === ',' ? addr.slice(0, -1) : addr
  return [
    addr,
    floor && ', #' + floor,
    unit && '-' + unit,
    isUsingPincode && pincode && `, Singapore ${pincode}`,
  ]
    .filter(Boolean)
    .join('')
}

const getAddressByPincode = async pincode => {
  const { data } = await get(
    `${PUBLIC_API_URL}/address/search?type=addresses&term=${pincode}`
  )

  if (Object.keys(data).length === 0) {
    throw new Error('NO_RESULTS')
  }

  return data.addresses[0]
}

export const getFormattedAddress = (address, isUsingPincode = true) => {
  const { metaData } = address
  address = {
    address: address.address,
    floor: (metaData && metaData.Floor) || null,
    unit: (metaData && metaData.Unit) || null,
    pincode: address.pincode,
  }
  return FormattedAddress({ addressInfo: address, isUsingPincode })
}

export default addressSearch

export { getProperAddressKey, FormattedAddress, getAddressByPincode }
